import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const languageMap = (lang) => {
    const map = {
      fr: "French",
      en: "English",
    };

    return map[lang];
  };

  return (
    <div className="dropdown is-hoverable">
      <div
        className="dropdown-trigger"
        style={{ display: "flex", alignItems: "center" }}
      >
        <button
          style={{
            backgroundColor: "transparent",
            color: "white",
            border: "none",
            textTransform: "none",
          }}
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu4"
        >
          <span>{languageMap(i18n.language)}</span>
        </button>
        <FontAwesomeIcon icon={faArrowDown} />
      </div>
      <div className="dropdown-menu" id="dropdown-menu4" role="menu">
        <div className="dropdown-content">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => changeLanguage(i18n.language === "fr" ? "en" : "fr")}
            className="dropdown-item"
          >
            {i18n.language === "fr" ? "English" : "French"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
