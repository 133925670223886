import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import makeStore from "./store/factory";

import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./i18n";

(async () => {
  // Initialize store
  const store = makeStore();

  // Create redux-persist persistor
  const persistor = persistStore(store);

  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>,
    document.getElementById("root")
  );
})();
