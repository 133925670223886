import { createSlice } from "@reduxjs/toolkit";
import { purge } from "./storage";

const initialState = () => ({
  isPromoModalVisible: false,
  currentStep: 1,
  isCustomPriceModalVisible: false,
  customPriceServiceId: -1,
});

const slice = createSlice({
  name: "ui",
  initialState: {
    ...initialState(),
  },
  reducers: {
    showPromoModal: (state, _) => {
      state.isPromoModalVisible = true;
    },
    hidePromoModal: (state, _) => {
      state.isPromoModalVisible = false;
    },
    showCustomPriceModal: (state, action) => {
      state.isCustomPriceModalVisible = true;
      state.customPriceServiceId = action.payload;
    },
    hideCustomPriceModal: (state, _) => {
      state.isCustomPriceModalVisible = false;
      state.customPriceServiceId = -1;
    },
    currentStepSet: (state, action) => {
      state.currentStep = action.payload.step;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, (_) => initialState());
  },
});

export default slice.reducer;

// Export reducer actions
const actions = slice.actions;
export const {
  hidePromoModal,
  showPromoModal,
  showCustomPriceModal,
  hideCustomPriceModal,
} = actions;

export const setCurrentStep = (step) => actions.currentStepSet({ step });

// Export reducer selectors
export const selectIsPromoModalVisible = (state) =>
  state.ui.isPromoModalVisible;

export const selectCurrentStep = (state) => state.ui.currentStep;

export const selectIsCustomPriceModalVisible = (state) =>
  state.ui.isCustomPriceModalVisible;

export const selectCustomPriceServiceId = (state) =>
  state.ui.customPriceServiceId;
