import React from "react";
import { useTranslation } from "react-i18next";

const ReturnToSite = () => {
  const { t } = useTranslation();

  return (
    <div className="site__link flex-item icon-left">
      <a href="https://cvert.ca/">
        <svg
          aria-hidden="true"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1667 7.91666H6.175L7.25833 6.84166C7.41525 6.68474 7.50341 6.47191 7.50341 6.25C7.50341 6.02808 7.41525 5.81525 7.25833 5.65833C7.10141 5.50141 6.88858 5.41325 6.66666 5.41325C6.44475 5.41325 6.23192 5.50141 6.075 5.65833L3.575 8.15833C3.49913 8.23758 3.43966 8.33104 3.4 8.43333C3.31665 8.63621 3.31665 8.86378 3.4 9.06666C3.43966 9.16896 3.49913 9.26241 3.575 9.34166L6.075 11.8417C6.15247 11.9198 6.24463 11.9818 6.34618 12.0241C6.44773 12.0664 6.55665 12.0882 6.66666 12.0882C6.77667 12.0882 6.8856 12.0664 6.98715 12.0241C7.08869 11.9818 7.18086 11.9198 7.25833 11.8417C7.33644 11.7642 7.39843 11.672 7.44074 11.5705C7.48305 11.4689 7.50483 11.36 7.50483 11.25C7.50483 11.14 7.48305 11.0311 7.44074 10.9295C7.39843 10.828 7.33644 10.7358 7.25833 10.6583L6.175 9.58333H14.1667C14.3877 9.58333 14.5996 9.67113 14.7559 9.82741C14.9122 9.98369 15 10.1956 15 10.4167V13.75C15 13.971 15.0878 14.183 15.2441 14.3393C15.4004 14.4955 15.6123 14.5833 15.8333 14.5833C16.0543 14.5833 16.2663 14.4955 16.4226 14.3393C16.5789 14.183 16.6667 13.971 16.6667 13.75V10.4167C16.6667 9.75362 16.4033 9.11774 15.9344 8.6489C15.4656 8.18006 14.8297 7.91666 14.1667 7.91666Z"
            fill="white"
          />
        </svg>
        <span>{t("return-to-website")}</span>
      </a>
    </div>
  );
};

export default ReturnToSite;
