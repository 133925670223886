import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SAFE_CHARGE_CDN_URL } from "../constants";
import loadScript from "../services/import";
import {
  selectCurrentPaymentId,
  selectStripeCustomerId,
} from "../store/contract";
import { getSavedCardDetails, selectPayment } from "../store/payment";

export let safeChargeInstance = null;

const useNuveiSafeCharge = () => {
  const dispatch = useDispatch();
  const { merchantId, merchantSiteId, env } = useSelector(selectPayment);
  const stripeCustomerId = useSelector(selectStripeCustomerId);
  const currentPaymentId = useSelector(selectCurrentPaymentId);

  useEffect(() => {
    if (merchantId && merchantSiteId && env) {
      loadScript(SAFE_CHARGE_CDN_URL, () => {
        safeChargeInstance = window.SafeCharge({
          env: env,
          merchantId: merchantId,
          merchantSiteId: merchantSiteId,
        });
      });
    }
  }, [merchantId, merchantSiteId, env]);

  useEffect(() => {
    const getPaymentOptions = async () => {
      const upoId = currentPaymentId?.split("-")[0];
      if (!upoId || !stripeCustomerId) return;
      dispatch(getSavedCardDetails(stripeCustomerId, upoId));
    };
    if (stripeCustomerId && currentPaymentId) {
      getPaymentOptions();
    }
  }, [stripeCustomerId, currentPaymentId]);

  return null;
};

export default useNuveiSafeCharge;
