import axios from "axios";
import * as endpoints from "../../endpoints";
import { API_URL } from "../../constants";
import {
  removeTokens,
  selectAuthenticated,
  selectExpiry,
  selectRefreshToken,
  updateTokens,
} from "../auth";
import { apiCallBegan } from "../api";

const auth =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (action.type !== apiCallBegan.type) return next(action);

    const { endpoint } = action.payload;

    // If endpoint does not require auth, or user is not logged in :
    // forward action without adding auth flag
    if (!endpoint.auth || !selectAuthenticated(getState())) {
      return next(action);
    }

    // If endpoint requires auth and user is logged in :
    // add auth flag to action for api middleware
    action.payload.auth = true;

    // If current accessToken is still valid :
    // forward without refreshing
    const expiry = selectExpiry(getState());
    const now = new Date().getTime();

    if (expiry > now + 60) {
      return next(action);
    }

    // If current accessToken is expired :
    // refresh and then forward
    const refreshToken = selectRefreshToken(getState());
    axios
      .request({
        baseURL: API_URL,
        url: endpoints.AUTH_REFRESH.url,
        method: "post",
        data: {
          refresh_token: refreshToken,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(updateTokens(res.data));
          localStorage.setItem("refreshToken", res.data.refresh_token);
        } else {
          dispatch(removeTokens({ message: res.data.message }));
        }

        next(action);
      })
      .catch((error) => {
        dispatch(removeTokens({ message: error.message }));
        localStorage.removeItem("refreshToken");

        next(action);
      });
  };

export default auth;
