import { API_URL, makeGoogleUrl } from "../constants";

export const loadGmap = async (callback) => {
  if (!("google" in window)) {
    const { key } = await fetch(`${API_URL}/address/gmap-config`).then((r) =>
      r.json()
    );
    const url = makeGoogleUrl(key);
    loadScript(url, callback);
  } else {
    callback();
  }
};

// dynamically load JavaScript files in our html with callback when finished
const loadScript = (url, callback) => {
  let script = document.createElement("script"); // create script tag
  script.type = "text/javascript";

  // when script state is ready and loaded or complete we will call callback
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url; // load by url
  document.getElementsByTagName("head")[0].appendChild(script); // append to head
};

export default loadScript;
