import "bulma-divider";
import "bulma-tooltip";
import "bulma/css/bulma.min.css";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { hotjar } from "react-hotjar";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import Error from "./components/Error";
import ContactForm from "./components/quote/ContactForm";
import useNuveiSafeCharge from "./hooks/useSafeCharge";
import { testApi } from "./services/api";
import {
  currentLocationUpdated,
  selectArea,
  selectLocation,
} from "./store/address";
import { loadUser, selectAuthenticated } from "./store/auth";
import { checkCurrentCoupon, validateCoupon } from "./store/cart";
import { updateContact } from "./store/contact";
import {
  createLead,
  loadContractComments,
  selectCurrentContract,
  selectOpportunityId,
  selectOpportunityStatus,
  selectReset,
  setAccountId,
  setContractId,
  setLeadId,
  setReset,
} from "./store/contract";
import {
  fetchContract,
  fetchLead,
  fetchOpportunity,
  loadAccount,
} from "./store/extra";
import {
  loadAllGroups,
  loadAllPrices,
  loadAllServiceDiscounts,
  loadContractDiscounts,
  loadExtraContractDiscounts,
  loadExtraPriceIds,
  loadExtraServiceDiscounts,
  loadServices,
  selectIsPublic,
  selectServices,
  setPublic,
} from "./store/offer";
import { getNuveiConfig } from "./store/payment";
import { purge } from "./store/storage";
import "./styles/App.scss";
import "./styles/PaymentCalendar.css";
import "./styles/styles.css";
import ScrollToTop from "./utils/scrollToTop";

const LoginForm = lazy(() => import("./components/LoginForm"));
const HomePage = lazy(() => import("./components/HomePage"));
const ServiceSelection = lazy(() => import("./components/ServiceSelection"));
const Contact = lazy(() => import("./components/Contact"));
const Details = lazy(() => import("./components/Details"));
const Payment = lazy(() => import("./components/payment"));
const Thanks = lazy(() => import("./components/quote/Thanks"));
const PackagesDetails = lazy(() =>
  import("./components/services/packages/PackageDetails")
);
const TermsConditions = lazy(() => import("./components/TermsConditions"));
const OpportunityClosedMessage = lazy(() =>
  import("./components/OpportunityClosedMessage")
);

const App = (props) => {
  // Global store states
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectAuthenticated);
  const isPublic = useSelector(selectIsPublic);
  const reset = useSelector(selectReset);
  const currentContract = useSelector(selectCurrentContract);
  const location = useSelector(selectLocation);
  const { lng, lat, currentLat, currentLng } = location;
  const opportunityId = useSelector(selectOpportunityId);
  const opportunityStatus = useSelector(selectOpportunityStatus);
  const area = useSelector(selectArea);
  const services = useSelector(selectServices);

  // Local UI States
  const [searchParams, _] = useSearchParams();
  const [apiLink, setApiLink] = useState(true);
  const dispatch = useDispatch();

  const isUsingCurrentLocation = () => {
    if (lat == currentLat && lng == currentLng) {
      dispatch(currentLocationUpdated(true));
    } else {
      dispatch(currentLocationUpdated(false));
    }
  };

  useEffect(() => {
    isUsingCurrentLocation();
  }, [lat, lng]);

  useEffect(() => {
    hotjar.initialize(3349221, 6);
    dispatch(getNuveiConfig());
  }, []);

  useEffect(async () => {
    const isPublic = !searchParams.has("internal");
    const isStart = searchParams.has("start");
    const hasLeadID = searchParams.has("leadId");
    const hasLead = searchParams.has("lead");
    const hasOpportunity = searchParams.has("opportunityId");
    const hasContract = searchParams.has("contractId");
    const hasName = searchParams.has("name");
    const hasPhone = searchParams.has("phone");
    const hasPostalCode = searchParams.has("postalCode");
    const hasUrl = searchParams.has("url");
    const hasAccount = searchParams.has("accountId");
    const hasEmail = searchParams.has("email");
    const hasCoupon = searchParams.has("coupon");

    if (
      isStart ||
      !isPublic ||
      reset ||
      hasLead ||
      hasOpportunity ||
      hasContract ||
      hasAccount
    )
      dispatch(purge());

    dispatch(loadUser());
    dispatch(loadServices());
    dispatch(loadAllGroups());
    dispatch(loadContractComments());
    dispatch(loadContractDiscounts());
    dispatch(loadAllServiceDiscounts());

    if (reset) dispatch(setReset(false));

    const apiLink = await testApi();
    setApiLink(apiLink);

    if (isAuthenticated && hasAccount) {
      const accountId = searchParams.get("accountId");
      dispatch(setAccountId(accountId));
      dispatch(loadAccount(accountId));
    }

    if (hasLeadID) {
      const leadId = searchParams.get("leadId");
      dispatch(setLeadId(leadId));
      dispatch(fetchLead(leadId));
    } else if (hasOpportunity) {
      const opportunityId = searchParams.get("opportunityId");
      dispatch(fetchOpportunity(opportunityId));
    } else if (hasContract) {
      const contractId = searchParams.get("contractId");
      dispatch(setContractId(contractId));
      dispatch(fetchContract(contractId));
    }

    if (hasLead && hasName && hasPhone && hasPostalCode) {
      const name = searchParams.get("name");
      const phone = searchParams.get("phone");
      const postalCode = searchParams.get("postalCode");
      const url = hasUrl ? searchParams.get("url") : "";
      const email = hasEmail ? searchParams.get("email") : "";
      dispatch(
        updateContact({
          firstName: name?.split(" ")[0],
          name: name?.split(" ")[1] ?? "",
          cellPhone: phone,
          otherPhone: phone,
          email,
          otherEmail: email,
        })
      );
      const gclid = searchParams.has("gclid") ? searchParams.get("gclid") : "";
      const fbclid = searchParams.has("fbclid")
        ? searchParams.get("fbclid")
        : "";
      const ttclid = searchParams.has("ttclid")
        ? searchParams.get("ttclid")
        : "";

      dispatch(
        createLead({ name, phone, postalCode, url, fbclid, gclid, ttclid })
      );
    }

    if (hasCoupon) {
      const coupon = searchParams.get("coupon");
      dispatch(validateCoupon(coupon));
    }

    // Remove all url parameters except url
    Array.from(searchParams.keys()).forEach((key) => {
      if (key !== "url") {
        searchParams.delete(key);
      }
    });

    // Rest of the code remains the same
    const newUrl = `${window.location.pathname}${
      searchParams.toString() ? "?" + searchParams.toString() : ""
    }`;
    window.history.replaceState(null, null, newUrl);

    // Update the URL in the browser
    window.history.replaceState(null, null, newUrl);

    dispatch(setPublic(isPublic));
  }, [searchParams]);

  useEffect(() => {
    dispatch(loadAllPrices());
  }, [currentContract, opportunityId, area, services]);

  useEffect(() => {
    dispatch(loadAllGroups());
    dispatch(loadExtraPriceIds());
    dispatch(loadExtraServiceDiscounts());
    dispatch(loadExtraContractDiscounts());
    dispatch(checkCurrentCoupon());
  }, [currentContract, opportunityId]);

  useEffect(() => {
    if (opportunityStatus === "Closed Won" && !isAuthenticated) {
      navigate("/closed");
    }
  }, [opportunityStatus]);

  if (!apiLink) {
    return <Error code="500" title="Erreur de communication avec le serveur" />;
  }

  return (
    <div className="Container">
      <Suspense fallback={<h3>Loading...</h3>}>
        <ScrollToTop>
          <Routes>
            {!isAuthenticated && !isPublic ? (
              <Route exact path="/" element={<LoginForm />} />
            ) : (
              <Route exact path="/" element={<HomePage />} />
            )}
            <Route exact path="/services" element={<ServiceSelection />} />
            <Route exact path="/details" element={<Details />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/payment" element={<Payment />} />
            <Route exact path="/contact_form" element={<ContactForm />} />
            <Route
              exact
              path="/terms_conditions"
              element={<TermsConditions />}
            />
            <Route exact path="/merci" element={<Thanks />} />
            <Route
              exact
              path="/closed"
              element={<OpportunityClosedMessage />}
            />
            <Route
              exact
              path="/package-details"
              element={<PackagesDetails />}
            />
          </Routes>
        </ScrollToTop>
      </Suspense>
    </div>
  );
};

export default App;
