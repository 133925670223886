import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  selectGoldenServices,
  selectAlwaysQuote,
  selectServiceDiscounts,
  selectServices,
  selectContractDiscounts,
} from "./offer";
import { apiCallBegan } from "./api";
import * as endpoints from "../endpoints";
import { purge } from "./storage";
import { contractDiscountsReceived } from "./extra";
import { selectCurrentContract, selectOpportunityId } from "./contract";
import { contractReceived, opportunityReceived } from "./extra";
import i18n from "../i18n";

const initialState = () => ({
  services: [],
  serviceDiscounts: [],
  contractDiscounts: [],
  paymentCalendar: null,
  package: -1,
  pestControl: -1,
  coupon: null,
  couponId: -1,
  couponCode: "",
  ui: {
    couponMessage: null,
  },
});

function setCartReducer(action, state) {
  action.payload.services.forEach((service) => {
    state.services.push(parseInt(service.id));
  });
  action.payload.contractDiscounts.forEach((discount) => {
    state.contractDiscounts.push(parseInt(discount.id));
  });
  action.payload.serviceDiscounts.forEach((discount) => {
    state.serviceDiscounts.push(parseInt(discount.id));
  });
  action.payload.coupons.forEach((coupon) => {
    state.couponId = parseInt(coupon.id);
  });
}

const slice = createSlice({
  name: "cart",
  initialState: initialState(),
  reducers: {
    serviceAdded: (state, action) => {
      state.services.push(action.payload.id);
    },
    serviceRemoved: (state, action) => {
      if (state.package === action.payload.id) state.package = -1;
      else if (state.pestControl === action.payload.id) state.pestControl = -1;
      state.services = state.services.filter((s) => s !== action.payload.id);
      state.serviceDiscounts = state.serviceDiscounts.filter(
        (s) => s.serviceId !== action.payload.id
      );
    },
    packageAdded: (state, action) => {
      state.services = state.services.filter((s) => s !== state.package);
      state.services.push(action.payload.id);
      state.package = action.payload.id;
    },
    packageRemoved: (state, action) => {
      state.serviceDiscounts = state.serviceDiscounts.filter(
        (s) => s.serviceId !== state.package
      );
      state.services = state.services.filter((s) => s !== state.package);
      state.package = -1;
    },
    pestControlAdded: (state, action) => {
      state.services = state.services.filter((s) => s !== state.pestControl);
      state.services.push(action.payload.id);
      state.pestControl = action.payload.id;
    },
    pestControlRemoved: (state, action) => {
      state.serviceDiscounts = state.serviceDiscounts.filter(
        (s) => s.serviceId !== state.pestControl
      );
      state.services = state.services.filter((s) => s !== state.pestControl);
      state.pestControl = -1;
    },
    serviceDiscountAdded: (state, action) => {
      state.serviceDiscounts.push(action.payload.id);
    },
    serviceDiscountRemoved: (state, action) => {
      state.serviceDiscounts = state.serviceDiscounts.filter(
        (d) => d !== action.payload.id
      );
    },
    serviceDiscountRemovedByServiceId: (state, action) => {
      state.serviceDiscounts = state.serviceDiscounts.filter(
        (d) =>
          d.service_id !== action.payload.serviceId ||
          d.group_id !== action.payload.grpId
      );
    },
    contractDiscountsReceived: (state, action) => {
      state.contractDiscounts = state.contractDiscounts.filter((id) =>
        action.payload.discounts.some((discount) => discount.id === id)
      );
    },
    contractDiscountAdded: (state, action) => {
      state.contractDiscounts.push(action.payload.id);
    },
    contractDiscountGroupRemoved: (state, action) => {
      state.contractDiscounts = state.contractDiscounts.filter(
        (id) =>
          !action.payload.contractDiscounts.some(
            (discount) => discount.id === id
          )
      );
    },
    contractDiscountRemoved: (state, action) => {
      state.contractDiscounts = state.contractDiscounts.filter(
        (id) => id !== action.payload.id
      );
    },
    couponReceived: (state, action) => {
      state.coupon = action.payload;
      state.ui.couponMessage = null;
    },
    couponCodeRecieved: (state, action) => {
      state.couponCode = action.payload;
    },
    couponRemoved: (state, action) => {
      state.coupon = null;
    },
    couponRefused: (state, action) => {
      if (i18n.language === "en")
        state.ui.couponMessage = "This coupon is invalid.";
      else state.ui.couponMessage = "Ce coupon est invalide.";
    },
    couponEmptied: (state, action) => {
      state.ui.couponMessage = "";
    },
    paymentCalendarSelected: (state, action) => {
      state.paymentCalendar = action.payload.id;
    },
    unselectPaymentCalendar: (state, action) => {
      state.paymentCalendar = -1;
    },
    oneOfManyServicesAdded: (state, action) => {
      state.services = state.services.filter(
        (s) => !action.payload.ofMany.includes(s)
      );
      state.services.push(action.payload.one);
    },
    serviceDiscountsRemovedByIds: (state, action) => {
      state.serviceDiscounts = state.serviceDiscounts.filter(
        (d) => !action.payload.ids.includes(d)
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, (_) => initialState());
    builder.addCase(opportunityReceived, (state, action) => {
      setCartReducer(action, state);
    });
    builder.addCase(contractReceived, (state, action) =>
      setCartReducer(action, state)
    );
  },
});

export default slice.reducer;

const { actions } = slice;

// Creators
export const addServiceToCart = (id) => actions.serviceAdded({ id });
export const removeServiceFromCart = (id) => actions.serviceRemoved({ id });

export const addOneOfManyServices = (one, ofMany) =>
  actions.oneOfManyServicesAdded({ one, ofMany });

export const unselectPaymentCalendar = () => actions.unselectPaymentCalendar();

export const addPackageToCart = (id) => actions.packageAdded({ id });
export const rmPackageFromCart = actions.packageRemoved;

export const addPestControlToCart = (id) => actions.pestControlAdded({ id });
export const rmPestControlFromCart = actions.pestControlRemoved;

export const addServiceDiscountToCart = (id) =>
  actions.serviceDiscountAdded({ id });
export const removeServiceDiscountById = (serviceId, grpId) =>
  actions.serviceDiscountRemovedByServiceId({
    serviceId: serviceId,
    grpId: grpId,
  });
export const removeServiceDiscountFromCart = (id) =>
  actions.serviceDiscountRemoved({ id });

export const addContractDiscountToCart = (discountId) =>
  actions.contractDiscountAdded({ id: discountId });

export const removeContractDiscountPreExist = (contractDiscounts) =>
  actions.contractDiscountGroupRemoved({ contractDiscounts });

export const removeContractDiscountFromCart = (id) =>
  actions.contractDiscountRemoved({ id });

export const checkCurrentCoupon = () => (dispatch, getState) => {
  const couponId = selectCouponId(getState());
  const currentContract = selectCurrentContract(getState());

  if (couponId === -1 || !currentContract) return;

  dispatch(
    apiCallBegan({
      endpoint: endpoints.COUPON_EXTRA_ID,
      data: {
        contract_id: currentContract.contractId,
        coupon_id: couponId,
      },
      onSuccess: actions.couponReceived.type,
      onEmpty: actions.couponEmptied.type,
      onError: actions.couponEmptied.type,
    })
  );
};

export const validateCoupon = (couponCode) =>
  apiCallBegan({
    endpoint: endpoints.COUPON,
    data: {
      coupon: couponCode,
    },
    onSuccess: actions.couponReceived.type,
    onEmpty: actions.couponRefused.type,
    onError: actions.couponRefused.type,
  });

export const removeServiceDiscounts = (ids) =>
  actions.serviceDiscountsRemovedByIds({ ids });

export const removeCoupon = () => actions.couponReceived(null);
export const emptyCouponMessage = () => actions.couponReceived(null);
export const setCouponCode = (code) => actions.couponCodeRecieved(code);
export const choosePaymentCalendar = (id) =>
  actions.paymentCalendarSelected({ id });

export const removeAllDiscounts = () => (dispatch, getState) => {
  const serviceDiscounts = selectServiceDiscountIdsInCart(getState());
  const contractDiscounts = selectContractDiscountsInCart(getState());

  if (serviceDiscounts) {
    serviceDiscounts.forEach((id) =>
      dispatch(actions.serviceDiscountRemoved({ id }))
    );
  }

  if (contractDiscounts) {
    contractDiscounts.forEach((id) =>
      dispatch(actions.contractDiscountRemoved({ id }))
    );
  }
};

// Selectors
export const selectCart = createSelector(
  (state) => state.cart,
  (cart) => cart
);

export const selectGoldensNotInCart = createSelector(
  (state) => state.cart.services,
  (state) => selectGoldenServices(state),
  (cartServices, goldenServices) =>
    goldenServices.filter((s) => !cartServices.includes(s.id))
);

export const selectUnblockedServices = createSelector(
  (state) => state.cart.services,
  (state) => selectServices(state),
  (cartServices, services) => {
    const getBlocks = (serviceId) =>
      services.find((service) => service.id === serviceId)?.blocks;

    const blocked = cartServices
      .map((cartService) => getBlocks(cartService))
      .reduce((acc, currentBlocks) => acc.concat(currentBlocks), [])
      .map((service) => service.id);

    return services
      .filter((service) => !blocked.includes(service.id))
      .map((service) => service.id);
  }
);

export const selectAvailablePackageServices = createSelector(
  (state) => state.cart.services,
  (state) => selectServices(state),
  (cartServices, services) => {
    const getBlocks = (serviceId) =>
      services.find((service) => service.id === serviceId).blocks;

    const blocked = cartServices
      .map((cartService) => getBlocks(cartService))
      .reduce((acc, currentBlocks) => acc.concat(currentBlocks), [])
      .map((service) => service.id);

    return blocked;
  }
);

export const selectPackageInCart = createSelector(
  (state) => state.cart.package,
  (cartPackage) => cartPackage
);

export const selectPestControlInCart = createSelector(
  (state) => state.cart.pestControl,
  (pestControl) => pestControl
);

export const selectCartEmpty = createSelector(
  (state) => state.cart,
  (cart) => cart.length === 0
);

export const selectCoupon = createSelector(
  (state) => state.cart.coupon,
  (coupon) => coupon
);

export const selectCouponId = createSelector(
  (state) => state.cart.couponId,
  (couponId) => couponId
);

export const selectCouponGroup = createSelector(
  (state) => state.cart.coupon,
  (state) => state.offer.groups,
  (coupon, groups) => {
    if (coupon) {
      const group = groups.find((group) => group.id === coupon.group_id);
      return group;
    }
  }
);

export const selectServiceDiscountIdsInCart = createSelector(
  (state) => state.cart.serviceDiscounts,
  (discounts) => discounts
);

export const selectTotalServicesInCart = createSelector(
  (state) => state.cart.services,
  (state) => state.offer.prices,
  (cart, prices) =>
    cart
      .map((id) => prices.find((p) => p.serviceId === id))
      .reduce((acc, price) => acc + price?.price ?? 0, 0)
);

export const selectCouponMessage = createSelector(
  (state) => state.cart.ui.couponMessage,
  (message) => message
);

export const selectServiceDiscountsIdsInCart = createSelector(
  (state) => state.cart.serviceDiscounts,
  (state) => state.cart.services,
  (discounts, services) => discounts.filter((id) => services.includes(id))
);

export const selectAvailableServiceDiscountsInCart = createSelector(
  (state) => state.offer.serviceDiscounts,
  (state) => state.cart.serviceDiscounts,
  (state) => state.cart.services,
  (serviceDiscounts, sDiscountsInCart, servicesInCart) =>
    serviceDiscounts
      .filter(
        (d) =>
          sDiscountsInCart.some((id) => id === d.id) &&
          servicesInCart.some((id) => id === d.service_id)
      )
      .map((d) => d.id)
);

export const selectDiscountsInCartByServiceId = (serviceId) =>
  createSelector(
    (state) => state.cart.serviceDiscounts,
    (state) => selectServiceDiscounts(serviceId)(state),
    (inCart, offered) => {
      if (inCart.length === 0) return [];
      return offered.filter((d) => inCart.some((o) => o === d.id));
    }
  );

export const selectContractDiscountsInCart = createSelector(
  (state) => state.cart.contractDiscounts,
  (inCart) => inCart
);

export const selectTotalDiscountsInCart = createSelector(
  (state) => state.cart.contractDiscounts,
  (state) => selectContractDiscounts(state),
  (state) => selectTotalServicesInCart(state),
  (inCart, offered, subTotal) => {
    if (inCart.length === 0) return 0;
    return offered
      .filter((discount) => inCart.includes(discount.id))
      .map((discount) => {
        if (discount.is_percentage) {
          return (discount.amount / 100) * subTotal;
        }
        return discount.amount;
      })
      .reduce((acc, curr) => acc + curr, 0);
  }
);

export const selectCouponTotal = createSelector(
  (state) => state.cart.coupon,
  (state) => selectTotalServicesInCart(state),
  (coupon, subTotal) => {
    if (!coupon) return 0;
    return coupon.is_percentage
      ? (coupon.amount / 100) * subTotal
      : coupon.amount;
  }
);

export const selectTotalServiceDiscountsInCart = createSelector(
  (state) => state.cart.serviceDiscounts,
  (state) => state.offer.serviceDiscounts,
  (state) => state.offer.prices,
  (state) => state.cart.services,
  (inCart, discounts, prices, cartServices) => {
    if (discounts.length === 0) {
      return 0;
    }
    return discounts
      .filter(({ service_id }) => cartServices.includes(service_id))
      .filter(({ id }) => inCart.includes(id))
      .map((discount) => {
        if (discount.is_percentage) {
          const price = prices.find(
            (price) => price.serviceId === discount.service_id
          );
          return price ? price.price * (discount.amount / 100) : 0;
        }
        return discount.amount;
      })
      .reduce((acc, curr) => acc + curr, 0);
  }
);

export const selectCartSubTotal = createSelector(
  (state) => selectTotalServicesInCart(state),
  (state) => selectTotalServiceDiscountsInCart(state),
  (state) => selectTotalDiscountsInCart(state),
  (state) => selectCouponTotal(state),
  (serviceTotal, serviceDiscounts, contractDiscounts, coupon) => {
    const number = serviceTotal - serviceDiscounts - contractDiscounts - coupon;
    return number > 0 ? number : 0;
  }
);

export const selectPaymentCalendarInCart = createSelector(
  (state) => state.cart.paymentCalendar,
  (calendar) => calendar
);

export const selectPackagesNotInCart = createSelector(
  (state) => selectAlwaysQuote(state),
  (state) => selectCart(state),
  (packages, { services: cart }) => {
    return packages.filter((s) => !cart.includes(s));
  }
);

export const selectOneOfManyServices = (ofMany) =>
  createSelector(
    (state) => selectCart(state),
    ({ services }) => {
      const service = services.find((s) => ofMany.includes(s));
      return service ? service : -1;
    }
  );

export const selectServicesInCart = createSelector(
  (state) => state.cart.services,
  (services) => services
);

export const selectIsCouponAllowed = createSelector(
  (state) => state.cart.serviceDiscounts,
  (state) => state.cart.contractDiscounts,
  (state) => selectOpportunityId(state),
  (serviceDiscounts, contractDiscounts, opportunityId) => {
    return (
      !opportunityId &&
      contractDiscounts.length === 0 &&
      serviceDiscounts.length === 0
    );
  }
);
