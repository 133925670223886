import { createSelector, createSlice } from "@reduxjs/toolkit";
import * as endpoints from "../endpoints";
import { apiCallBegan } from "./api";

const initialState = () => ({
  user: initialUser(),
  ui: {
    loginMessage: "",
    loading: "",
  },
  accessToken: "",
  refreshToken: localStorage.getItem("refreshToken") || "",
  expiry: -1,
  user_id: -1,
});

const initialUser = () => ({
  email: "",
  roles: [],
  features: [],
});

const slice = createSlice({
  name: "auth",
  initialState: {
    ...initialState(),
  },
  reducers: {
    userRequested: (state, _) => {
      state.ui.loading = true;
    },
    userReceived: (state, action) => {
      state.user = action.payload.user;
      state.user.features = action.payload.features;
      state.ui.loginMessage = "";
      state.ui.loading = false;
    },
    userRequestFailed: (state, _) => {
      state.ui.loading = false;
    },
    loginStarted: (state, _) => {
      state.ui.loading = true;
    },
    loginFailed: (state, action) => {
      state.ui.loginMessage = action.payload.message;
      state.ui.loading = false;
    },
    tokenUpdated: (state, action) => {
      const { refresh_token, access_token, expiry, user_id } = action.payload;
      state.refreshToken = refresh_token;
      state.accessToken = access_token;
      state.expiry = expiry;
      state.user_id = user_id;
      state.ui.loading = false;
    },
  },
});

export default slice.reducer;

const { actions } = slice;

// Action creators
export const updateTokens = (tokens) => actions.tokenUpdated(tokens);

export const removeTokens = () =>
  actions.tokenUpdated({
    refreshToken: "",
    accessToken: "",
    expiry: -1,
    user_id: -1,
  });

export const logout = () => (dispatch) => {
  dispatch(removeTokens());
  localStorage.setItem("refreshToken", "");
};

export const loginUser = (email, password) =>
  apiCallBegan({
    endpoint: endpoints.LOGIN,
    data: { email, password },
    onSuccess: actions.tokenUpdated.type,
    onError: actions.loginFailed.type,
  });

export const loadUser = () =>
  apiCallBegan({
    endpoint: endpoints.CURRENT_USER,
    onSuccess: actions.userReceived.type,
  });

// Selectors
export const selectAuthenticated = createSelector(
  (state) => state.auth.refreshToken,
  (token) => Boolean(token && token !== "")
);

export const selectAdmin = createSelector(
  (state) => state.auth.user,
  (user) =>
    Boolean(
      user.roles.length > 0 &&
        user.roles
          .map((role) => role.name)
          .filter((name) => name.toLowerCase() === "admin").length > 0
    )
);

export const selectLoading = createSelector(
  (state) => state.auth.ui,
  (ui) => ui.loading
);

export const selectUser = createSelector(
  (state) => state.auth.user_id,
  (user_id) => user_id
);

export const selectAccessToken = createSelector(
  (state) => state.auth.accessToken,
  (accessToken) => accessToken
);

export const selectFeatures = createSelector(
  (state) => state.auth.user.features,
  (features) => features
);
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectExpiry = (state) => state.auth.expiry;
