export const add_data_layer = (event, data, step) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    quote_step: step,
    version: "A",
    ...data,
  });
};

export const format_cart_service_data = (
  servicesInCart,
  services,
  prices,
  servicesInput
) => {
  const items = [];
  servicesInCart.map((serviceInCart) => {
    const service = services.find((s) => s.id === serviceInCart);
    const service_price = prices.find((s) => s.serviceId === serviceInCart);
    const service_input = servicesInput[serviceInCart];
    if (service_price)
      items.push({
        item_name: `${service.title}`,
        item_id: `${serviceInCart}`,
        price: `${service_price.price}`,
        item_brand: "cvert",
        item_category: "package",
        quantity:
          service.type === "qty" && service_input !== undefined
            ? service_input
            : "1",
      });
  });
  return { ecommerce: { items: items } };
};

export const format_services_data = (services, prices, servicesInput) => {
  const items = [];
  services.map((service, index) => {
    const service_price = prices.find((s) => s.serviceId === service.id);
    const service_input = servicesInput[service.id];
    let item_list_id = "1";
    if (lawnServices.includes(service.slug)) {
      item_list_id = "2";
    } else if (pestServices.includes(service.slug)) {
      item_list_id = "3";
    } else if (otherServices.includes(service.slug)) {
      item_list_id = "4";
    }
    if (service_price)
      items.push({
        item_name: `${service.title}`,
        item_id: `${service.id}`,
        price: `${service_price.price}`,
        item_brand: "cvert",
        item_category: "package",
        item_list_name: "package",
        item_list_id: item_list_id,
        index: `${index}`,
        quantity:
          service.type === "qty" && service_input !== undefined
            ? service_input
            : "1",
      });
  });
  return { ecommerce: { items: items } };
};

export const add_payment_data = (subTotal, tax, contractId, coupon, data) => {
  const rate = tax.reduce((acc, { rate }) => acc + rate, 0);
  const taxValue = subTotal * rate;
  data.ecommerce.currency = "CAD";
  data.ecommerce.value = `${subTotal.toFixed(2)}`;
  data.ecommerce.tax = `${taxValue.toFixed(2)}`;
  data.ecommerce.shipping = "0";
  data.ecommerce.transaction_id = contractId;
  data.ecommerce.coupon = coupon;

  return data;
};

export const add_contact_data = (contact, postalCode, data) => {
  return {
    email: contact.email,
    postal_code: postalCode,
    phone: contact.cellPhone,
    ...data,
  };
};

const lawnServices = [
  "aeration",
  "digitaire_pre",
  "digitaire_post",
  "amendement",
  "vers_blancs",
  "augmentation",
];
const pestServices = ["traitement_insectes_garanti", "traitement_insectes"];
const otherServices = [
  "sac_de_semences",
  "fertilisation_haies",
  //"fertilisation_arbres_arbustes",
  // $ / entrée manuelle
  "tonte",
  // $ / entrée manuelle
  "ouverture",
  // $ / entrée manuelle
  "ensemencement",
  // $ prix directement avec deux options
  "terreautage",
  "tonte_apres_ferti",
];
