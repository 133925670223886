import { createSelector, createSlice } from "@reduxjs/toolkit";
import { purge } from "./storage";

const initialState = () => ({
  isLoading: false,
  loaderMessage: "",
});

const slice = createSlice({
  name: "loader",
  initialState: initialState(),
  reducers: {
    updateLoadingStatus: (state, action) => {
      state.isLoading = action.payload.loadingStatus;
    },
    updateLoadingMessage: (state, action) => {
      state.loaderMessage = action.payload.loadingMessage;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, (state) => initialState());
  },
});

export default slice.reducer;
const { actions } = slice;

export const setLoadStatus = (loadingStatus) =>
  actions.updateLoadingStatus({ loadingStatus });

export const setLoadingMessage = (loadingMessage) =>
  actions.updateLoadingMessage({ loadingMessage });

export const selectLoadStatus = (state) => state.loader.isLoading;

export const selectLoadMessage = (state) => state.loader.loaderMessage;
