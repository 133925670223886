import { createAction } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import * as endpoints from "../endpoints";
import { selectFormatedAddress } from "./address";

export const opportunityReceived = createAction("extra/opportunityReceived");
export const contractReceived = createAction("extra/contractReceived");
export const customPriceReceived = createAction("extra/customPriceReceived");
export const accountReceived = createAction("extra/accountReceived");
export const leadReceived = createAction("extra/leadReceived");
export const areaPolygonsReceived = createAction("extra/areaPolygonsReceived");

export const getPolygonFromDB = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      endpoint: endpoints.GET_POLYGONS,
      data: {
        ...selectFormatedAddress(getState()),
      },
      onSuccess: areaPolygonsReceived.type,
    })
  );
};

export const fetchOpportunity = (id) =>
  apiCallBegan({
    endpoint: endpoints.OPPORTUNITY,
    data: { id },
    onSuccess: opportunityReceived.type,
  });

export const fetchContract = (id) =>
  apiCallBegan({
    endpoint: endpoints.CONTRACT,
    data: { id },
    onSuccess: contractReceived.type,
  });

export const loadCustomPrice = (serviceId, price) =>
  apiCallBegan({
    endpoint: endpoints.CUSTOM_PRICES,
    data: {
      service_id: serviceId,
      price: price,
    },
    onSuccess: customPriceReceived.type,
  });
export const loadAccount = (accountId) =>
  apiCallBegan({
    endpoint: endpoints.ACCOUNT,
    data: {
      id: accountId,
    },
    onSuccess: accountReceived.type,
  });

export const fetchLead = (id) =>
  apiCallBegan({
    endpoint: endpoints.LEAD_FETCH,
    data: { id },
    onSuccess: leadReceived.type,
  });
