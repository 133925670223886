import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { purge } from "../../store/storage";
import BackButton from "../BackButton";
import Header from "../Header";
import ReturnToSite from "../ReturnToSite";
import { useTranslation } from "react-i18next";

const Thanks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  // useEffect(() => {
  //   dispatch(purge());
  // }, []);

  return (
    <div>
      <Header RenderRightLabelComp={<ReturnToSite />} />
      <main className="container">
        <div className="site__title p-y p-x-site text-center">
          <h1 className="h3">Soumission en ligne</h1>
        </div>
        <div>
          <section className="tab__container  tab-1-section" id="tab-1">
            <div className="tab__content" style={{ padding: "4rem 2rem" }}>
              <BackButton
                style={{ position: "absolute", top: "1.5rem", left: "1.5rem" }}
                onClick={() => navigate("/")}
              />
              <h4 className="h4" style={{ textAlign: "center" }}>
                {t("address-not-found")}
              </h4>
              <div>
                <h5 style={{ textAlign: "center", fontSize: "1rem" }}>
                  {t("dont-worry-lower")}
                  <br />
                  <br />
                  <a href="tel:18444128378">
                    {t("alternatively")}, <strong>{t("click-here")}</strong>{" "}
                    {t("call-team")}{" "}
                    <strong className="nowrap">1 844 41C-VERT</strong>,{" "}
                    {t("one-mint")}
                  </a>
                </h5>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default Thanks;
