import { createSelector, createSlice } from "@reduxjs/toolkit";
import { purge } from "./storage";
import { apiCallBegan } from "./api";
import * as endpoints from "../endpoints";
import {
  accountReceived,
  contractReceived,
  leadReceived,
  opportunityReceived,
} from "./extra";

const initialState = () => ({
  firstName: "",
  name: "",
  cellPhone: "",
  otherPhone: "",
  email: "",
  otherEmail: "",
  billingSame: true,
  commercial: false,
  disableNotifications: false,
  consentsEmail: false,
  updateAccount: true,
  billingCivic: "",
  billingCity: "",
  billingApt: "",
  billingStreet: "",
  billingPostal: "",
  billingProvince: "",
  leadSource: "",
});

const slice = createSlice({
  name: "contact",
  initialState: initialState(),
  reducers: {
    contactReceived: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    leadSourceReceived: (state, action) => {
      state.leadSource = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, (_) => initialState());
    builder.addCase(opportunityReceived, setContactReducerFnc);
    builder.addCase(contractReceived, setContactReducerFnc);
    builder.addCase(accountReceived, setContactReducerFnc);
    builder.addCase(leadReceived, (state, action) => {
      state.cellPhone = action.payload.phone;
      state.firstName = action.payload.firstName;
      state.name = action.payload.lastName;
    });
  },
});

const setContactReducerFnc = (
  state,
  {
    payload: {
      email,
      firstName,
      lastName,
      phone,
      billingCivicNumber,
      billingCity,
      billingStreet,
      billingPostalCode,
      billingState,
    },
  }
) => {
  state.firstName = firstName;
  state.name = lastName;
  state.cellPhone = phone;
  state.email = email;
  state.billingCivic = billingCivicNumber;
  state.billingStreet = billingStreet;
  state.billingCity = billingCity;
  state.billingPostal = billingPostalCode;
  state.billingProvince = billingState;
};

export default slice.reducer;

const { actions } = slice;

// Action creators
export const updateContact = (contactInfos) =>
  actions.contactReceived(contactInfos);

export const addLeadSource = (source) => actions.leadSourceReceived(source);

// Selectors
export const selectContact = createSelector(
  (state) => state.contact,
  (contact) => contact
);

export const selectContactFullName = createSelector(
  (state) => state.contact,
  (contact) => `${contact.firstName} ${contact.name}`
);
