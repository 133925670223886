import { API_URL } from "../constants";

export const mapServices = (services, inputs, prices, selected = true) =>
  services.map((id) => ({
    id,
    price_id: prices.find((price) => price.serviceId === id).id,
    input: id in inputs ? inputs[id] : 1,
    selected: selected,
  }));

export const mapServiceDiscount = (serviceDiscounts) =>
  serviceDiscounts.map((id) => ({ id }));

export const mapDiscount = (discounts) => discounts.map((id) => ({ id }));

export const mapTaxes = (taxes) => taxes.map((t) => t.id);

export const confirmContract = async ({
  stripeCustomerId: stripe_customer_id,
  stripeSetupIntentId: stripe_setup_intent_id,
  quoteId: quote_id,
  paymentCalendarId: payment_calendar_id,
  paymentMethod: payment_method,
  sessionToken: session_token,
}) => {
  return await fetch(`${API_URL}/upload/contract`, {
    method: "POST",
    body: JSON.stringify({
      stripe_customer_id,
      stripe_setup_intent_id,
      quote_id,
      payment_calendar_id,
      payment_method,
      session_token,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((r) => (r.status === 200 ? r.json() : {}))
    .catch((error) => {
      console.log(error.message);
      return {};
    });
};

export const mapAuth = ({ user_id: user_id }) => ({
  user_id,
});

export const confirmDeposit = async ({ uuid: uuid }) =>
  await fetch(`${API_URL}/upload/confirm-contract`, {
    method: "POST",
    body: JSON.stringify({
      uuid,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((r) => r.json())
    .catch(() => ({ error: "Unable to process card" }));

export const mapContact = ({
  firstName: first_name,
  cellPhone: cell_phone,
  otherPhone: other_phone,
  otherEmail: other_email,
  billingSame: billing_same,
  disableNotifications: disable_notifications,
  billingCivic: billing_civic,
  billingApt: billing_apt,
  billingStreet: billing_street,
  billingPostal: billing_postal,
  billingProvince: billing_province,
  billingCity: billing_city,
  leadSource: lead_source,
  name,
  email,
  commercial,
}) => ({
  first_name,
  other_phone,
  cell_phone,
  other_email,
  billing_same,
  disable_notifications,
  billing_civic,
  billing_apt,
  billing_street,
  billing_postal,
  billing_province,
  billing_city,
  lead_source,
  name,
  email,
  commercial,
});

export const mapLocation = ({
  civicNumber: no_civic,
  postalCode: postal_code,
  addressId: address_id,
  street,
  city,
  state,
  country,
  lat,
  lng,
  precision,
  confirmed,
}) => ({
  no_civic,
  address_id,
  postal_code,
  street,
  city,
  state,
  country,
  lat,
  lng,
  precision,
  confirmed,
  province: "QC",
});

export const updateContract = async (
  transactionAmendment,
  quoteId,
  userId,
  intentId,
  paymentType,
  stripeCustomerId,
  paymentCalendarId,
  contractOriginalValue
) => {
  return await fetch(`${API_URL}/upload/update-contract`, {
    method: "POST",
    body: JSON.stringify({
      user_id: userId,
      quote_id: quoteId,
      transaction_amendment: transactionAmendment,
      intent_id: intentId,
      payment_method: paymentType,
      stripe_customer_id: stripeCustomerId,
      payment_calendar_id: paymentCalendarId,
      contract_original_value: contractOriginalValue,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((r) => r.status === 200)
    .catch((error) => {
      console.log(error.message);
      return false;
    });
};
