import React from "react";
import PropTypes from "prop-types";

const Error = (props) => (
  <div className="container">
    <div className="columns is-vcentered">
      <div className="column has-text-centered">
        <br />
        <h1 className="title">{`${props.code} - ${props.title}`}</h1>
        {props.message && <p className="subtitle">{props.message}</p>}
      </div>
    </div>
  </div>
);

export default Error;

Error.propTypes = {
  code: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
};
