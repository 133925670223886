import { createSlice } from "@reduxjs/toolkit";
import * as endpoints from "../endpoints";
import { apiCallBegan } from "./api";
import { purge } from "./storage";

const initialState = () => ({
  merchantId: "",
  merchantSiteId: "",
  env: "int",
  savedCardDetails: null,
});

const slice = createSlice({
  name: "payment",
  initialState: initialState(),
  reducers: {
    setNuveiConfig: (state, action) => {
      state.merchantId = action.payload.merchant_id;
      state.merchantSiteId = action.payload.site_id;
      state.env = action.payload.env;
    },
    setSavedCardDetails: (state, action) => {
      state.savedCardDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add extra reducers here
    builder.addCase(purge, (_) => initialState());
  },
});

export default slice.reducer;

const { actions } = slice;

export const getNuveiConfig = () =>
  apiCallBegan({
    endpoint: endpoints.NUVEI_CONFIG,
    onSuccess: actions.setNuveiConfig.type,
  });

export const getSavedCardDetails = (userId, upoId) =>
  apiCallBegan({
    params: { user_id: userId, upo_id: upoId },
    endpoint: endpoints.NUVEI_PAYMENT_OPTION,
    onSuccess: actions.setSavedCardDetails.type,
  });

export const selectPayment = (state) => state.payment;
