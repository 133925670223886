import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import * as endpoints from "../endpoints";
import {
  selectAvailableServiceDiscountsInCart,
  selectCart,
  selectCartSubTotal,
  selectPackagesNotInCart,
} from "./cart";
import { selectContact } from "./contact";
import { purge } from "./storage";
import { selectServiceInputs, selectServicePrices } from "./offer";
import {
  accountReceived,
  contractReceived,
  customPriceReceived,
  opportunityReceived,
} from "./extra";
import { selectUser } from "./auth";
import { setLoadStatus } from "./loading";
import { selectLocation } from "./address";
import {
  mapAuth,
  mapContact,
  mapDiscount,
  mapLocation,
  mapServiceDiscount,
  mapServices,
  mapTaxes,
} from "../services/quote";

const initialState = () => ({
  taxes: [],
  serviceComments: [],
  serviceAnswers: [],
  contractComments: [],
  contractAnswers: [],
  stripeCustomerId: "",
  currentPaymentId: "",
  paymentDetails: {},
  clientSecret: "",
  paymentIntentId: "",
  customerId: -1,
  quoteId: -1,
  accountId: "",
  accountName: "",
  opportunityId: "",
  opportunityStatus: "",
  previouslyProcessed: false,
  depositCharged: false,
  leadId: "",
  reset: false,
  paymentType: "card",
  transactionAmendment: "Charge",
  currentContract: {
    contractId: "",
    previousTotal: -1,
    nbTransactions: -1,
    nbPaidTransactions: -1,
    totalPaid: -1,
    stripeCustomerId: "",
    contractDiscounts: [],
    serviceDiscounts: [],
    extraPrices: [],
    coupons: [],
  },
  ui: {
    quoteLoading: false,
    quoteConfirmed: false,
    quoteError: null,
  },
});

const slice = createSlice({
  name: "contract",
  initialState: initialState(),
  reducers: {
    taxesReceived: (state, action) => {
      state.taxes = action.payload.taxes;
    },
    serviceCommentsReceived: (state, action) => {
      state.serviceComments = action.payload;
      const newAnswers = [];

      action.payload.forEach((service) => {
        service.comments.forEach((comment) => {
          const existingAnswerIndex = state.serviceAnswers.findIndex(
            ({ id }) => id === comment.id
          );
          if (existingAnswerIndex === -1) {
            // Add new answer
            newAnswers.push({
              id: comment.id,
              comment: comment.default_value,
              files: [],
            });
          } else {
            // Update existing answer
            newAnswers.push(state.serviceAnswers[existingAnswerIndex]);
          }
        });
      });

      state.serviceAnswers = newAnswers;
    },
    serviceCommentsFilled: (state, action) => {
      for (const answer of action.payload) {
        const index = state.serviceAnswers.findIndex((a) => a.id === answer.id);
        if (index !== -1) {
          state.serviceAnswers[index].comment = answer.comment;
        } else {
          const comment = state.serviceComments.find((c) => c.id === answer.id);
          state.serviceAnswers.push({
            id: answer.id,
            comment: answer.comment,
            files: [],
          });
        }
      }
    },
    contractCommentsReceived: (state, action) => {
      state.contractComments = action.payload.comments;
      action.payload.comments
        .filter((c) => c.default_value)
        .forEach((comment) => {
          if (!state.contractAnswers.some(({ id }) => id === comment.id)) {
            state.contractAnswers.push({
              id: comment.id,
              files: [],
              ...(comment.default_value && { comment: comment.default_value }),
            });
          }
        });
    },

    serviceCommentFilesSet: (state, action) => {
      const serviceObjectIndex = state.serviceAnswers.findIndex(
        (service) => service.id === action.meta.comment_id
      );
      if (serviceObjectIndex !== -1) {
        if (
          state.serviceAnswers[serviceObjectIndex] &&
          state.serviceAnswers[serviceObjectIndex].files
        ) {
          state.serviceAnswers[serviceObjectIndex].files = action.payload.files;
        }
      }
    },

    contractCommentFilesSet: (state, action) => {
      const contractCommentIndex = state.contractAnswers.findIndex(
        (comment) => comment.id === action.meta.comment_id
      );
      if (contractCommentIndex !== -1) {
        if (
          state.contractAnswers[contractCommentIndex] &&
          state.contractAnswers[contractCommentIndex].files
        ) {
          state.contractAnswers[contractCommentIndex].files =
            action.payload.files;
        }
      }
    },

    removeAllFilesFromContractComment: (state, action) => {
      const commentIndex = state.contractAnswers.findIndex(
        (comment) => comment.id === action.payload.commentId
      );
      if (commentIndex !== -1) {
        state.contractAnswers[commentIndex].files = [];
      }
    },

    removeAllFilesFromServiceComment: (state, action) => {
      const commentIndex = state.serviceAnswers.findIndex(
        (comment) => comment.id === action.payload.commentId
      );
      if (commentIndex !== -1) {
        state.serviceAnswers[commentIndex].files = [];
      }
    },

    contractCommentsFilled: (state, action) => {
      for (const answer of action.payload) {
        const index = state.contractAnswers.findIndex(
          (a) => a.id === answer.id
        );
        if (index !== -1) {
          state.contractAnswers[index].comment = answer.comment;
        } else {
          const comment = state.contractComments.find(
            (c) => c.id === answer.id
          );
          state.contractAnswers.push({
            id: answer.id,
            comment: answer.comment,
            files: [],
          });
        }
      }
    },
    customerIdReceived: (state, action) => {
      state.customerId = action.payload.customerId;
    },
    quoteIdReceived: (state, action) => {
      state.quoteId = action.payload.quoteId;
    },
    accountIdReceived: (state, action) => {
      state.accountId = action.payload.accountId;
    },
    stripeCustomerIdReceived: (state, action) => {
      state.stripeCustomerId = action.payload.customer_id;
    },
    stripePaymentDetailsReceived: (state, action) => {
      state.paymentDetails = { ...action.payload };
    },
    paymentIntentIdSet: (state, action) => {
      state.paymentIntentId = action.payload.id;
    },
    resetSet: (state, action) => {
      state.reset = action.payload.reset;
    },
    opportunityIdSet: (state, action) => {
      state.opportunityId = action.payload.id;
    },
    leadIdSet: (state, action) => {
      state.leadId = action.payload.id;
    },
    contractIdSet: (state, action) => {
      state.currentContract.contractId = action.payload.id;
    },
    transactionAmendmentSet: (state, action) => {
      state.transactionAmendment = action.payload.amendment;
    },
    customPriceReceived: (state, action) => {
      state.currentContract.extraPrices =
        state.currentContract.extraPrices.filter(
          (p) => action.payload.id !== p
        );

      state.currentContract.extraPrices =
        state.currentContract.extraPrices.concat(action.payload.id);
    },
    customPriceRemoved: (state, action) => {
      state.currentContract.extraPrices =
        state.currentContract.extraPrices.filter((p) => action.payload !== p);
    },
    extraPricesRemoved: (state, action) => {
      state.currentContract.extraPrices = [];
    },
    selectedExtraPricesRemoved: (state, action) => {
      state.currentContract.extraPrices =
        state.currentContract.extraPrices.filter(
          (p) => !action.payload.includes(p)
        );
    },
    serviceDiscountRemoved: (state, action) => {
      state.currentContract.serviceDiscounts =
        state.currentContract.serviceDiscounts.filter(
          (d) => action.payload.id !== d
        );
    },
    contractDiscountRemoved: (state, action) => {
      state.currentContract.contractDiscounts =
        state.currentContract.contractDiscounts.filter(
          (d) => action.payload.id !== d
        );
    },
    paymentTypeSet: (state, action) => {
      state.paymentType = action.payload.type;
    },
    quoteFormSubmitted: (state, action) => {
      state.ui.quoteLoading = true;
    },
    quoteFormAsyncStarted: (state, action) => {
      state.quoteId = action.payload.quote_id;
    },
    quoteFormFailed: (state, action) => {
      state.ui.quoteLoading = false;
      state.ui.quoteConfirmed = false;
      state.ui.quoteError = action.payload;
    },
    quoteFormSucceeded: (state, action) => {
      state.ui.quoteLoading = false;
      state.ui.quoteConfirmed = true;
      state.ui.quoteError = null;
    },
    resetQuoteConfirmed: (state, action) => {
      state.ui.quoteConfirmed = false;
    },
    resetQuoteError: (state, action) => {
      state.ui.quoteError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, (_) => initialState());
    builder.addCase(contractReceived, (state, action) => {
      state.currentContract = {
        ...state.currentContract,
        nbTransactions: action.payload.nbTransactions,
        nbPaidTransactions: action.payload.nbPaidTransactions,
        previousTotal: action.payload.totalPrice,
        totalPaid: action.payload.totalPaid,
        contractDiscounts: action.payload.contractDiscounts.map((d) => d.id),
        serviceDiscounts: action.payload.serviceDiscounts.map((d) => d.id),
        coupon:
          action.payload.coupons.length > 0
            ? action.payload.coupons[0].id
            : null,
        stripeCustomerId: action.payload.stripeCustomerId,
      };

      state.currentContract.extraPrices = action.payload.services.map((s) =>
        parseInt(s.priceId)
      );
      action.payload.contractComments.forEach((answer) => {
        state.contractAnswers.push(answer);
      });
      action.payload.serviceComments.forEach((answer) => {
        state.serviceAnswers.push(answer);
      });
    });
    builder.addCase(opportunityReceived, (state, action) => {
      state.opportunityId = action.payload.opportunityId ?? "";
      state.opportunityStatus = action.payload.status ?? "";
      state.previouslyProcessed = action.payload.previouslyProcessed ?? false;
      state.quoteId = action.payload.quoteId ?? -1;

      state.stripeCustomerId = action.payload.stripeCustomerId ?? "";
      state.currentPaymentId = action.payload.paymentMethodId ?? "";

      state.currentContract = {
        ...state.currentContract,
        extraPrices: action.payload.services.map((s) => parseInt(s.priceId)),
        serviceDiscounts: action.payload.serviceDiscounts.map((d) => d.id),
        contractDiscounts: action.payload.contractDiscounts.map((d) => d.id),
      };

      state.currentContract = {
        ...state.currentContract,
        contractId: action.payload.contractId ?? "",
        nbTransactions: action.payload.nbTransactions ?? 0,
        nbPaidTransactions: action.payload.nbPaidTransactions ?? 0,
        previousTotal: action.payload.totalPrice ?? 0,
        totalPaid: action.payload.totalPaid ?? 0,
        stripeCustomerId: action.payload.stripeCustomerId ?? "",
      };

      action.payload.contractComments.forEach((answer) => {
        state.contractAnswers = state.contractAnswers.filter(
          ({ id }) => id !== answer.id
        );
        state.contractAnswers.push({
          files: [],
          ...answer,
        });
      });
      action.payload.serviceComments.forEach((answer) => {
        state.serviceAnswers = state.serviceAnswers.filter(
          ({ id }) => id !== answer.id
        );
        state.serviceAnswers.push({
          files: [],
          ...answer,
        });
      });
    });
    builder.addCase(customPriceReceived, (state, action) => {
      state.currentContract.extraPrices =
        state.currentContract.extraPrices.filter(
          (p) => action.payload.id !== p
        );

      state.currentContract.extraPrices =
        state.currentContract.extraPrices.concat(action.payload.id);
    });
    builder.addCase(accountReceived, (state, action) => {
      state.accountName = action.payload.name;
      state.stripeCustomerId = action.payload.stripeCustomerId;
      state.currentPaymentId = action.payload.stripePaymentId;
    });
  },
});

export default slice.reducer;

const { actions } = slice;

// Action creators
export const loadTaxes = () =>
  apiCallBegan({
    endpoint: endpoints.TAXES,
    data: {
      province: "QC",
    },
    onSuccess: actions.taxesReceived.type,
  });

export const loadServiceComments = () => (dispatch, getState) => {
  const { services: cart } = selectCart(getState());

  dispatch(
    apiCallBegan({
      endpoint: endpoints.SERVICE_COMMENTS,
      data: {
        services: cart,
      },
      onSuccess: actions.serviceCommentsReceived.type,
    })
  );
};

export const loadContractComments = () =>
  apiCallBegan({
    endpoint: endpoints.CONTRACT_COMMENTS,
    onSuccess: actions.contractCommentsReceived.type,
  });

export const loadAccount = (accountId) =>
  apiCallBegan({
    endpoint: endpoints.ACCOUNT,
    data: {
      id: accountId,
    },
    onSuccess: actions.accountReceived.type,
  });

export const loadCustomPrice = (serviceId, price) => (dispatch, getState) => {
  const prices = selectServicePrices(getState());
  const extraPriceIds = selectExtraPrices(getState());

  const existingPrice = prices
    .filter((p) => extraPriceIds.includes(p.id))
    .find((p) => p.serviceId === serviceId);

  if (existingPrice) {
    dispatch(actions.customPriceRemoved(existingPrice.id));
  }

  dispatch(
    apiCallBegan({
      endpoint: endpoints.CUSTOM_PRICES,
      data: {
        service_id: serviceId,
        price: price,
      },
      onSuccess: actions.customPriceReceived.type,
    })
  );
};

export const uploadServiceCommentFiles = (files, comment_id) => (dispatch) => {
  const formData = new FormData();
  for (const file of files) {
    formData.append("files", file);
  }

  dispatch(
    apiCallBegan({
      endpoint: endpoints.FILE_UPLOAD,
      data: formData,
      meta: {
        comment_id: comment_id,
      },
      onSuccess: actions.serviceCommentFilesSet.type,
    })
  );
};

export const uploadContractCommentFiles = (files, comment_id) => (dispatch) => {
  const formData = new FormData();
  for (const file of files) {
    formData.append("files", file);
  }

  dispatch(
    apiCallBegan({
      endpoint: endpoints.FILE_UPLOAD,
      data: formData,
      meta: {
        comment_id: comment_id,
      },
      onSuccess: actions.contractCommentFilesSet.type,
    })
  );
};

export const removeAllFilesFromContractComment = (commentId) =>
  actions.removeAllFilesFromContractComment({ commentId });

export const removeAllFilesFromServiceComment = (commentId) =>
  actions.removeAllFilesFromServiceComment({ commentId });

export const resetQuoteConfirmed = () => actions.resetQuoteConfirmed();

export const resetQuoteError = () => actions.resetQuoteError();

export const setServiceCommentsAnswers = (data) =>
  actions.serviceCommentsFilled(data);

export const setContractCommentsAnswers = (data) =>
  actions.contractCommentsFilled(data);

export const setOpportunityId = (id) => actions.opportunityIdSet({ id });

export const setLeadId = (id) => actions.leadIdSet({ id });

export const setCustomerId = (customerId) =>
  actions.customerIdReceived({ customerId });

export const setQuoteId = (quoteId) => actions.quoteIdReceived({ quoteId });

export const setAccountId = (accountId) =>
  actions.accountIdReceived({ accountId });

export const setStripeCustomerId = (customerId) =>
  actions.stripeCustomerIdReceived({ customerId });

export const setPaymentIntentId = (id) => actions.paymentIntentIdSet({ id });

export const setReset = (reset) => actions.resetSet({ reset });

export const setContractId = (id) => actions.contractIdSet({ id });

export const createLead = (data) =>
  apiCallBegan({
    endpoint: endpoints.LEAD_CREATE,
    data: data,
    onSuccess: actions.leadIdSet.type,
  });

export const sendQuote = (id) =>
  apiCallBegan({
    endpoint: endpoints.SEND_QUOTE,
    data: { id },
  });

export const setTransactionAmendment = (amendment) =>
  actions.transactionAmendmentSet({ amendment });

export const removeExtraPrices = () => actions.extraPricesRemoved();

export const removeSelectedContractExtraPrices =
  (ids) => (dispatch, getState) => {
    const prices = selectServicePrices(getState());
    const extraPriceIds = selectExtraPrices(getState());

    const selectedPriceIds = prices
      .filter((p) => extraPriceIds.includes(p.id))
      .filter((p) => ids.includes(p.serviceId))
      .map((p) => p.id);

    dispatch(actions.selectedExtraPricesRemoved(selectedPriceIds));
  };

export const setPaymentType = (type) => actions.paymentTypeSet({ type });

export const removeDiscountsInContract = () => (dispatch, getState) => {
  const currentContract = selectCurrentContract(getState());

  if (currentContract.serviceDiscounts) {
    currentContract.serviceDiscounts.forEach((id) =>
      dispatch(actions.serviceDiscountRemoved({ id }))
    );
  }

  if (currentContract.contractDiscounts) {
    currentContract.contractDiscounts.forEach((id) =>
      dispatch(actions.contractDiscountRemoved({ id }))
    );
  }
};

// TODO : Refactor state queries into smaller selectors
export const confirmQuote = (consentsEmail) => (dispatch, getState) => {
  dispatch(setLoadStatus(true));
  dispatch(actions.quoteFormSubmitted);

  const currentContract = selectCurrentContract(getState());
  const user = selectUser(getState());

  const cart = {
    ...selectCart(getState()),
    packages: selectPackagesNotInCart(getState()),
    serviceDiscounts: selectAvailableServiceDiscountsInCart(getState()),
  };
  const contact = selectContact(getState());
  const address = selectLocation(getState());
  const inputs = selectServiceInputs(getState());
  const prices = selectServicePrices(getState());
  const contractAnswers = selectContractAnswers(getState());
  const serviceAnswers = selectServiceAnswers(getState());
  const opportunityId = selectOpportunityId(getState());
  const leadId = selectLeadId(getState());
  const taxes = selectTaxes(getState());
  const accountId = selectAccountId(getState());
  const quoteId = selectQuoteId(getState());

  dispatch(
    apiCallBegan({
      endpoint: endpoints.UPLOAD_QUOTE,
      data: {
        customer: {
          ...mapContact(contact),
          ...mapLocation(address),
          ...mapAuth(user),
        },
        services: [
          ...mapServices(cart.services, inputs, prices),
          ...mapServices(cart.packages, inputs, prices, false),
        ],
        service_discounts: mapServiceDiscount(cart.serviceDiscounts),
        contract_discounts: mapDiscount(cart.contractDiscounts),
        service_comments: serviceAnswers,
        contract_comments: contractAnswers,
        external_id: opportunityId,
        opportunityId: opportunityId,
        lead_id: leadId,
        user_id: user,
        contract_id: currentContract.contractId,
        ...(cart.coupon && { coupon: { id: cart.coupon.id } }),
        taxes: mapTaxes(taxes),
        consents_email: consentsEmail,
        account_id: user > 0 ? accountId : "",
        quote_id: quoteId,
      },
      onAsyncSuccess: actions.quoteFormAsyncStarted.type,
      onSuccess: actions.quoteFormSucceeded.type,
      onError: actions.quoteFormFailed.type,
    })
  );
};

// Selector
export const selectTaxes = createSelector(
  (state) => state.contract.taxes,
  (taxes) => taxes
);

export const selectCartWithTax = createSelector(
  (state) => selectCartSubTotal(state),
  (state) => state.contract.taxes,
  (subTotal, taxes) => {
    if (!taxes || taxes.length === 0) return subTotal;
    const rate = taxes.reduce((acc, { rate }) => rate + acc, 0);
    return subTotal + Math.round(subTotal * rate * 100) / 100;
  }
);

export const selectServiceCommentsInCart = createSelector(
  (state) => selectCart(state),
  (state) => state.contract.serviceComments,
  (inCart, serviceComments) =>
    serviceComments.filter((comment) =>
      inCart.services.includes(comment.service)
    )
);

export const selectServiceComments = createSelector(
  (state) => selectServiceCommentsInCart(state),
  (inCart) => inCart.reduce((acc, group) => acc.concat(group.comments), [])
);

export const selectContractComments = createSelector(
  (state) => state.contract.contractComments,
  (comments) => comments
);

export const selectStripeCustomerId = createSelector(
  (state) => state.contract.stripeCustomerId,
  (id) => id
);

export const selectCurrentPaymentId = createSelector(
  (state) => state.contract.currentPaymentId,
  (id) => id
);

export const selectCurrentContractStripeCustomerId = createSelector(
  (state) => state.contract.currentContract.stripeCustomerId,
  (id) => id
);

export const selectCustomerId = createSelector(
  (state) => state.contract.customerId,
  (id) => id
);

export const selectReset = createSelector(
  (state) => state.contract.reset,
  (reset) => reset
);

export const selectQuoteId = createSelector(
  (state) => state.contract.quoteId,
  (id) => id
);

export const selectContractAnswers = createSelector(
  (state) => state.contract.contractAnswers,
  (answers) => answers
);

export const selectServiceAnswers = createSelector(
  (state) => state.contract.serviceAnswers,
  (answers) => answers
);

export const selectOpportunityId = createSelector(
  (state) => state.contract.opportunityId,
  (id) => id
);

export const selectLeadId = createSelector(
  (state) => state.contract.leadId,
  (id) => id
);

export const selectCurrentContract = createSelector(
  (state) => state.contract.currentContract,
  (contract) => contract
);

export const selectHasFilledAnswer = createSelector(
  (state) => selectServiceCommentsInCart(state),
  (state) => selectContractComments(state),
  (state) => selectServiceAnswers(state),
  (state) => selectContractAnswers(state),
  (sComments, cComments, sAnswers, cAnswers) => {
    sComments = sComments.reduce(
      (acc, group) => acc.concat(group.comments),
      []
    );
    sComments = sComments.filter((c) => c.is_mandatory);
    cComments = cComments.filter((c) => c.is_mandatory);

    const sMissing = sComments.filter(
      (c) => !sAnswers.some((a) => a.id === c.id)
    );
    const cMissing = cComments.filter(
      (c) => !cAnswers.some((a) => a.id === c.id)
    );

    return sMissing.length === 0 && cMissing.length === 0;
  }
);

export const selectTransactionAmendment = createSelector(
  (state) => state.contract.transactionAmendment,
  (amendment) => amendment
);

export const hasExtraIds = createSelector(
  (state) => state.contract,
  (contract) => contract.currentContract.extraPrices.length > 0
);

export const selectAccountId = createSelector(
  (state) => state.contract.accountId,
  (accountId) => accountId
);

export const selectAccountName = createSelector(
  (state) => state.contract.accountName,
  (accountName) => accountName
);

export const selectPaymentDetails = createSelector(
  (state) => state.contract.paymentDetails,
  (paymentDetails) => paymentDetails
);

export const selectPaymentType = createSelector(
  (state) => state.contract.paymentType,
  (paymentType) => paymentType
);

export const selectExtraPrices = createSelector(
  (state) => state.contract.currentContract.extraPrices,
  (extraPrices) => extraPrices
);

export const selectPreviousContractTotal = createSelector(
  (state) => state.contract,
  (contract) => contract.currentContract.previousTotal
);

export const selectPreviouslyProcessed = createSelector(
  (state) => state.contract.previouslyProcessed,
  (previouslyProcessed) => previouslyProcessed
);
export const selectOpportunityStatus = createSelector(
  (state) => state.contract.opportunityStatus,
  (opportunityStatus) => opportunityStatus
);
export const selectDepositCharged = createSelector(
  (state) => state.contract.depositCharged,
  (depositCharged) => depositCharged
);
export const selectUIQuoteConfirmed = createSelector(
  (state) => state.contract.ui,
  (ui) => ui.quoteConfirmed
);
export const selectUIQuoteError = createSelector(
  (state) => state.contract.ui,
  (ui) => ui.quoteError
);

export const makeSelectFilesByContractCommentId = (comment_id) =>
  createSelector(
    (state) => state.contract,
    (contract) => {
      const matchingContractAnswer = contract.contractAnswers.find(
        (answer) => answer.id === comment_id
      );
      return matchingContractAnswer ? matchingContractAnswer.files : [];
    }
  );

export const makeSelectFilesByServiceCommentId = (comment_id) =>
  createSelector(
    (state) => state.contract.serviceAnswers,
    (serviceAnswers) => {
      const matchingServiceAnswer = serviceAnswers.find(
        (answer) => answer.id === comment_id
      );
      return matchingServiceAnswer ? matchingServiceAnswer.files : [];
    }
  );
