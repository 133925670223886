import React, { useEffect } from "react";
import logo from "../assets/svg/logo.svg";
import PropTypes from "prop-types";
import { selectCurrentStep, setCurrentStep } from "../store/ui";
import { useDispatch, useSelector } from "react-redux";
import Stepper from "./Stepper";
import { add_data_layer } from "../services/dataLayer";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import { resetQuoteConfirmed, resetQuoteError } from "../store/contract";

const Header = ({ RenderRightLabelComp, showStepper }) => {
  const currentStep = useSelector(selectCurrentStep);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const dispatch = useDispatch();

  const cStep = () => {
    if (window.location.pathname === "/") {
      dispatch(setCurrentStep(1));
    } else if (window.location.pathname === "/services") {
      dispatch(setCurrentStep(2));
    } else if (window.location.pathname === "/details") {
      dispatch(setCurrentStep(3));
    } else if (window.location.pathname === "/contact") {
      dispatch(setCurrentStep(4));
      dispatch(resetQuoteConfirmed());
      dispatch(resetQuoteError());
    } else if (window.location.pathname === "/payment") {
      dispatch(setCurrentStep(5));
    }
  };

  useEffect(() => {
    cStep();
    add_data_layer("quote_step", {}, `${currentStep}`);
  }, [window.location.pathname]);

  return (
    <header
      id="header"
      style={{ position: "relative" }}
      className="flex-container p-y p-y-0"
    >
      <div className="site__logo flex-item">
        <a href="/">
          <img src={logo} alt="Logo cVert" width="107" height="34" />
        </a>
      </div>
      {showStepper && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "65%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Stepper
            noOfSteps={5}
            currentStep={currentStep}
            setCurrentStep={(step) => dispatch(setCurrentStep(step))}
          />
        </div>
      )}
      {/* <div className="step-in-header" >
          {currentStep}
      </div> */}
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <p style={{marginBottom: 0}} onClick={() => changeLanguage("en")}  >change language</p> */}
        {/* <LanguageSelector/> */}
        <div
          style={{ marginRight: "1.5rem" }}
          className="site__title flex-item"
        >
          <span>{t("online-submission")}</span>
        </div>
        {RenderRightLabelComp}
      </div>
    </header>
  );
};

Header.defaultProps = {
  RenderRightLabelComp: null,
  showStepper: true,
};

Header.prototype = {
  RenderRightLabelComp: PropTypes.node,
  showStepper: PropTypes.bool,
};

export default Header;
