import axios from "axios";
import { io } from "socket.io-client";
import { API_URL, API_SOCKET_URL } from "../../constants";
import { apiCallBegan, apiCallFail, apiCallSuccess } from "../api";
import { selectAccessToken } from "../auth";

// TODO : Refactor into smaller functions
const api =
  ({ dispatch, getState }) =>
  (next) => {
    return (action) => {
      if (action.type !== apiCallBegan.type) return next(action);

      const {
        endpoint,
        data,
        params,
        auth,
        onStart,
        onSuccess,
        onError,
        onEmpty,
        onAsyncSuccess,
        meta,
      } = action.payload;

      // Dispatch onStart action if required
      if (onStart) dispatch({ type: onStart });

      // Propagate current action before doing api call
      next(action);

      // Fetch accessToken if necessary, empty string otherwise
      const token = auth ? selectAccessToken(getState()) : "";
      axios
        .request({
          baseURL: API_URL,
          url: endpoint.url,
          method: endpoint.method,
          data,
          params,
          headers: {
            "Content-type": endpoint.contentType
              ? endpoint.contentType
              : "application/json",
            // Only add authorization field if auth is required
            ...(auth && { Authorization: `Bearer ${token}` }),
          },
        })
        .then(({ data, status }) => {
          // General
          dispatch(apiCallSuccess(data));

          if (endpoint.async) {
            if (onAsyncSuccess)
              dispatch({ type: onAsyncSuccess, payload: data, meta: meta });

            const taskId = data.task_id;

            const socket = io(API_SOCKET_URL, {
              transports: ["websocket"],
              upgrade: false,
            });

            //return error if no status received after 35 seconds
            let statusTimeout = setTimeout(() => {
              dispatch({
                type: onError,
                payload: { message: "Error :  server not responding" },
              });
              socket.close();
            }, 35000);
            socket.on("connect", function () {
              console.log("Socket connected. Emitting join for task:", taskId);
              socket.emit("join", { task_id: `${taskId}` });
            });

            socket.on("status", function (res) {
              clearTimeout(statusTimeout);
              console.log("Received status:", res);
              const taskStatus = res.state;
              if (["SUCCESS", "FAILURE"].includes(taskStatus)) {
                if (taskStatus === "SUCCESS") {
                  dispatch({ type: onSuccess, payload: res, meta: meta });
                } else if (taskStatus === "FAILURE") {
                  dispatch({ type: onError, payload: res.error });
                }

                console.log("Closing socket.");
                socket.close();
              } else {
                console.log("Resend status check call.");
                socket.emit("get_updated_status", { task_id: `${taskId}` });
              }
            });

            socket.on("error", function (error) {
              console.log("Socket.IO Error", error);
            });

            socket.on("disconnect", function (reason) {
              console.log("Socket.IO disconnected:", reason);
            });
          } else {
            dispatch({ type: onSuccess, payload: data, meta: meta });
            if (onEmpty && status === 204)
              dispatch({ type: onEmpty, payload: data });
          }
        })
        .catch((error) => {
          dispatch(apiCallFail(error.message));
          if (onError) dispatch({ type: onError, payload: error.message });
        });
    };
  };

export default api;
