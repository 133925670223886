export const OPPORTUNITY = {
  url: "/integration/opportunity",
  method: "post",
  auth: true,
};

export const CONTRACT = {
  url: "/integration/contract",
  method: "post",
  auth: true,
};

export const LEAD_FETCH = {
  url: "/integration/lead",
  method: "post",
  auth: true,
};

export const LEAD_CREATE = {
  url: "/integration/lead/create",
  method: "post",
  auth: false,
};

export const SERVICE_COMMENTS = {
  url: "/comments/services",
  method: "post",
  auth: true,
};

export const CONTRACT_COMMENTS = {
  url: "/comments/contract",
  method: "get",
  auth: true,
};

export const PAYMENT_CALENDARS = {
  url: "/payments/calendar",
  method: "get",
  auth: true,
};

export const COUPON = {
  url: "/discounts/coupon",
  method: "post",
  auth: false,
};

export const COUPON_EXTRA_ID = {
  url: "/discounts/coupon/extra-id",
  method: "post",
  auth: false,
};

export const TAXES = {
  url: "/payments/taxes",
  method: "post",
  auth: false,
};

export const CONTRACT_DISCOUNTS = {
  url: "/discounts/contract",
  method: "post",
  auth: true,
};

export const CONTRACT_DISCOUNTS_EXTRA_IDS = {
  url: "/discounts/contract/extra-ids",
  method: "post",
  auth: false,
};

export const SERVICE_DISCOUNTS = {
  url: "/discounts/services",
  method: "post",
  auth: true,
};

export const SERVICE_DISCOUNTS_EXTRA_IDS = {
  url: "/discounts/services/extra-ids",
  method: "post",
  auth: false,
};

export const GROUPS = {
  url: "/discounts/group",
  method: "get",
  auth: false,
};

export const PRICES = {
  url: "/services/prices",
  method: "post",
  auth: true,
};

export const PRICE_EXTRA_IDS = {
  url: "/services/prices/extra-ids",
  method: "post",
  auth: false,
};

export const CUSTOM_PRICES = {
  url: "/services/prices/custom",
  method: "post",
  auth: true,
};

export const SERVICES = {
  url: "/services/",
  method: "get",
  auth: true,
};

export const AVAILABLE_SERVICES = {
  url: "/services/available",
  method: "get",
  auth: true,
};

export const ADDRESS = {
  url: "/address/",
  method: "post",
  auth: false,
};

export const UPDATE_AREA = {
  url: "/address/",
  method: "put",
  auth: true,
};

export const ADD_POLYGONS = {
  url: "/address/add_polygons",
  method: "post",
  auth: true,
};

export const GET_POLYGONS = {
  url: "/address/get_polygons_by_address",
  method: "post",
  auth: true,
};

export const AUTH_REFRESH = {
  url: "/auth/refresh",
  method: "post",
  auth: false,
};

export const LOGIN = {
  url: "/auth/login",
  method: "post",
  auth: false,
};

export const CURRENT_USER = {
  url: "/auth/status",
  method: "get",
  auth: true,
};

export const POSTAL_CODES = {
  url: "/address/postal_code",
  method: "get",
  auth: false,
};

export const SEND_QUOTE = {
  url: "/upload/send-quote",
  method: "post",
  auth: true,
};

export const FILE_UPLOAD = {
  url: "/upload/upload-files",
  method: "post",
  contentType: "multipart/form-data",
  auth: true,
};

export const ADD_NEW_ADDRESS = {
  url: "/address/add-address",
  method: "post",
  auth: true,
};

export const VALIDATE_ADDRESS = {
  url: "/integration/validate",
  method: "post",
  auth: true,
};

export const ACCOUNT = {
  url: "/integration/account",
  method: "post",
  auth: true,
};

export const UPLOAD_QUOTE = {
  url: "/upload/quote",
  method: "post",
  auth: true,
  async: true,
};

export const NUVEI_CONFIG = {
  url: "/payments/nuvei-config",
  method: "get",
};

export const NUVEI_SESSION_TOKEN = {
  url: "/payments/nuvei-session-token",
  method: "post",
};
export const NUVEI_PAYMENT_OPTION = {
  url: "/payments/nuvei-payment-option",
  method: "get",
};
