import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const BackButton = ({ onClick, style, className }) => {
  return (
    <div
      className={`back-button ${className}`}
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        ...style,
      }}
    >
      {/* <FontAwesomeIcon icon="arrow-left" color='black'/> */}
      <div
        className="back-icon"
        // style={{width: "2rem", height: "2rem", background: "green", borderRadius: "50%"}}
      >
        <FontAwesomeIcon icon={faArrowLeft} size="xs" />
      </div>
      <p style={{ marginBottom: "0", marginLeft: ".5rem" }}>Back</p>
    </div>
  );
};

export default BackButton;
