import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { selectHasFilledAddress, selectArea } from "../store/address";
import { selectContact } from "../store/contact";
import { selectCart, selectPackageInCart } from "../store/cart";
import {
  selectContractComments,
  selectServiceComments,
  selectHasFilledAnswer,
} from "../store/contract";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Steps = (props) => {
  const { currentStep, setCurrentStep } = props;

  const hasFilledAddress = useSelector(selectHasFilledAddress);
  const area = useSelector(selectArea);
  const hasFilledAnswer = useSelector(selectHasFilledAnswer);
  const cComments = useSelector(selectContractComments);
  const sComments = useSelector(selectServiceComments);
  const cart = useSelector(selectCart);
  const { firstName, email, cellPhone } = useSelector(selectContact);
  const { t, i18n } = useTranslation();
  const steps = useMemo(() => {
    let allowedSteps = [
      { step: 1, disabled: true, label: t("address") },
      { step: 2, disabled: true, label: "Services" },
      { step: 3, disabled: true, label: t("details") },
      { step: 4, disabled: true, label: "Contact" },
      { step: 5, disabled: true, label: t("paiement") },
    ];
    let maxStep;
    if (!hasFilledAddress || currentStep === 1) {
      maxStep = 1;
    } else if (cart.services.length === 0 || area === 0 || currentStep === 2) {
      maxStep = 2;
    } else if (!hasFilledAnswer || currentStep === 3) {
      maxStep = 3;
    } else if (
      firstName === "" ||
      email === "" ||
      cellPhone === "" ||
      currentStep === 4
    ) {
      maxStep = 4;
    } else {
      maxStep = 5;
    }

    allowedSteps = allowedSteps.map((s) =>
      s.step <= maxStep ? { ...s, disabled: false } : s
    );
    return allowedSteps.sort((a, b) => a.step - b.step);
  }, [
    hasFilledAddress,
    cart,
    firstName,
    hasFilledAnswer,
    sComments,
    cComments,
    i18n.language,
  ]);

  const navigate = useNavigate();

  const handleOnClick = (step) => {
    setCurrentStep(step);
    if (step === 1) {
      navigate("/");
    } else if (step === 2) {
      navigate("/services");
    } else if (step === 3) {
      navigate("/details");
    } else if (step === 4) {
      navigate("/contact");
    } else {
      navigate("/payment");
    }
  };

  return (
    <nav className="tab__nav p-y p-x-site">
      <ul>
        {steps.map((item) => (
          <li style={{ position: "relative" }} key={item.step}>
            <a
              className={`tab__link ${
                currentStep >= item.step && "active"
              } tab-${item.step}`}
              style={{
                margin: "auto",
                cursor: item.disabled && "not-allowed",
                color: item.disabled && "gray",
                background: item.disabled && "#eee",
              }}
              onClick={() => !item.disabled && handleOnClick(item.step)}
            >
              {item.step}
            </a>
            <p style={{ fontSize: "12px" }}>{item.label}</p>
            <div
              // style={{}}
              className={`${item.step === 5 ? "line none" : "line"} ${
                currentStep > item.step && "active"
              } `}
            />
          </li>
        ))}
      </ul>
    </nav>
  );
};

Steps.defaultProps = {
  currentStep: 1,
  setCurrentStep: () => {},
};

Steps.propTypes = {
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func,
};

export default Steps;
