import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import logger from "redux-logger";
import { persistReducer } from "redux-persist";
import { FLUSH, PAUSE, REHYDRATE } from "redux-persist/es/constants";
import storage from "redux-persist/lib/storage";
import addressReducer from "./address";
import authReducer from "./auth";
import cartReducer from "./cart";
import contactReducer from "./contact";
import contractReducer from "./contract";
import loaderReducer from "./loading";
import api from "./middleware/api";
import auth from "./middleware/auth";
import offerReducer from "./offer";
import paymentReducer from "./payment";
import uiReducer from "./ui";

const reducer = combineReducers({
  auth: authReducer,
  address: addressReducer,
  offer: offerReducer,
  cart: cartReducer,
  contract: contractReducer,
  contact: contactReducer,
  ui: uiReducer,
  loader: loaderReducer,
  payment: paymentReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const makeStore = () => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE],
        },
      }).concat([
        auth,
        api,
        ...(!!process.env.REACT_APP_IS_DEBUG ? [logger] : []),
      ]),
  });
};

export default makeStore;
