import { createSelector, createSlice } from "@reduxjs/toolkit";
import { shallowEqual } from "react-redux";
import * as endpoints from "../endpoints";
import { apiCallBegan } from "./api";
import { purge } from "./storage";
import {
  areaPolygonsReceived,
  contractReceived,
  leadReceived,
  opportunityReceived,
} from "./extra";
import { ADD_POLYGONS, GET_POLYGONS } from "../endpoints";

const initialLocation = () => ({
  civicNumber: "",
  street: "",
  address_line_2: "",
  city: "",
  state: "",
  postalCode: "",
  country: "",
  lat: 0,
  lng: 0,
  precision: "",
  confirmed: false,
  addressId: -1,
  usingCurrentLocation: false,
  currentLat: -1,
  currentLng: -1,
});

const initialState = () => ({
  choices: {
    addresses: {},
    city: [],
    civicNumber: [],
    street: [],
    address_line_2: [],
  },
  location: initialLocation(),
  area: -1,
  manual_area: false,
  hasContract: false,
  ui: {
    showMap: false,
    input: "gmap",
    messageStatus: "",
    message: "",
  },
  polygons: {
    encodedPolygon: [],
    polygonLatLng: [],
    polygonArea: [],
  },
  packageManualArea: false,
  servicesPolygon: {},
  servicesManualArea: {},
});

const slice = createSlice({
  name: "address",
  initialState: initialState(),
  reducers: {
    choicesReceived: (state, action) => {
      const data = action.payload;
      const choices = {
        city: [],
        street: [],
        civicNumber: [],
        address_line_2: [],
        addresses: data.cities,
      };

      Object.entries(data.cities).forEach(([city, streetObj]) => {
        choices.city.push(city);

        Object.entries(streetObj).forEach(
          ([street, civicAndSecondStreetObj]) => {
            choices.street.push(street);
            Object.entries(civicAndSecondStreetObj["civic"]).forEach(
              ([civicNumber, street2List]) => {
                choices.civicNumber.push(civicNumber);
                choices.address_line_2 =
                  choices.address_line_2.concat(street2List);
              }
            );
          }
        );
      });

      state.choices = choices;
    },
    locationUpdated: (state, action) => {
      state.location = {
        ...state.location,
        ...action.payload.values,
      };
    },
    clearLocation: (state, action) => {
      state.location = initialLocation();
    },
    updateIsUsingCurrentLocation: (state, action) => {
      state.location.usingCurrentLocation = action.payload.value;
    },
    clearChoices: (state, action) => {
      state.choices = {
        addresses: {},
        city: [],
        civicNumber: [],
        street: [],
      };
    },
    updatePackageManualArea: (state, action) => {
      state.packageManualArea = action.payload.packageManualArea;
    },
    updateCurrentCoordinates: (state, action) => {
      state.location.currentLat = action.payload.lat;
      state.location.currentLng = action.payload.lng;
    },
    addressReceived: (state, action) => {
      state.area = action.payload.area;
      state.manual_area = action.payload.manual_area;
      state.location.addressId = action.payload.id;
    },
    areaReceived: (state, action) => {
      state.area = action.payload.area;
    },
    areaEmptied: (state, action) => {
      state.area = 0;
    },
    mapShown: (state, action) => {
      state.ui.showMap = true;
    },
    mapHidden: (state, action) => {
      state.ui.showMap = false;
    },
    addressInputSelected: (state, action) => {
      state.ui.input = action.payload.input;
    },
    addAddressMsg: (state, action) => {
      state.ui.addAddressMessage = action.payload.message;
      state.ui.messageStatus = "message";
    },
    addAddressError: (state, action) => {
      state.ui.addAddressMessage = action.payload;
      state.ui.messageStatus = "error";
    },
    validationReceived: (state, action) => {
      state.hasContract = action.payload.hasContract;
    },
    addPolygon: (state, action) => {
      state.polygons.encodedPolygon.push(action.payload.encodedPolygon);
      state.polygons.polygonLatLng.push(action.payload.polygonLatLng);
      state.polygons.polygonArea.push(action.payload.polygonArea);
    },
    removePolygon: (state, action) => {
      state.polygons.encodedPolygon = [];
      state.polygons.polygonLatLng = [];
      state.polygons.polygonArea = [];
    },
    addServicePolygons: (state, action) => {
      const serviceId = action.payload.serviceId;
      if (!state.servicesPolygon.hasOwnProperty(serviceId)) {
        state.servicesPolygon[serviceId] = {
          encodedPolygon: [action.payload.data.encodedPolygon],
          polygonLatLng: [action.payload.data.polygonLatLng],
          polygonArea: [action.payload.data.polygonArea],
        };
      } else {
        state.servicesPolygon[serviceId].encodedPolygon.push(
          action.payload.data.encodedPolygon
        );
        state.servicesPolygon[serviceId].polygonLatLng.push(
          action.payload.data.polygonLatLng
        );
        state.servicesPolygon[serviceId].polygonArea.push(
          action.payload.data.polygonArea
        );
      }
    },
    removeServicePolygons: (state, action) => {
      const serviceId = action.payload.serviceId;
      if (state.servicesPolygon.hasOwnProperty(serviceId)) {
        delete state.servicesPolygon[serviceId];
      }
    },
    addServiceManualArea: (state, action) => {
      const serviceId = action.payload.serviceId;
      state.servicesManualArea[serviceId] = action.payload.servicesManualArea;
    },
    removeServiceManualArea: (state, action) => {
      const serviceId = action.payload.serviceId;
      if (state.servicesManualArea.hasOwnProperty(serviceId)) {
        delete state.servicesManualArea[serviceId];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purge, (state) => initialState());
    builder.addCase(opportunityReceived, setAddressReducer);
    builder.addCase(contractReceived, setAddressReducer);
    //builder.addCase(leadReceived, setAddressReducer);
    builder.addCase(areaPolygonsReceived, setPolygonsReducer);
  },
});

const setPolygonsReducer = (state, action) => {
  state.packageManualArea = true;
  for (const [serviceID, polygons] of Object.entries(action.payload)) {
    state.servicesPolygon[serviceID] = polygons;
    state.servicesManualArea[serviceID] = true;
  }
};

const setAddressReducer = (
  state,
  {
    payload: {
      civicNumber,
      street,
      secondLine,
      city,
      state: addressState,
      postalCode,
      country,
      lat,
      lng,
      precision,
    },
  }
) => {
  state.location.civicNumber = civicNumber ?? "";
  state.location.street = street ?? "";
  state.location.address_line_2 = secondLine ?? "";
  state.location.city = city ?? "";
  state.location.state = addressState && postalCode ? addressState : "";
  state.location.postalCode = postalCode ?? "";
  state.location.country = country && postalCode ? country : "";
  state.location.lat = lat ?? 0;
  state.location.lng = lng ?? 0;
  state.location.precision = precision ?? "";
  state.ui.showMap = true;
};

export default slice.reducer;

const { actions } = slice;

// Action creators
export const updateLocation = (values) => actions.locationUpdated({ values });
export const updatePackageManualArea = (values) =>
  actions.updatePackageManualArea({ ...values });
export const addServicePolygons = (values) =>
  actions.addServicePolygons({ ...values });
export const removeServicePolygons = (values) =>
  actions.removeServicePolygons({ ...values });
export const addServiceManualArea = (values) =>
  actions.addServiceManualArea({ ...values });
export const removeServiceManualArea = (values) =>
  actions.removeServiceManualArea({ ...values });
export const addPolygons = (values) => actions.addPolygon({ ...values });
export const removePolygons = () => actions.removePolygon();

export const currentLocationUpdated = (value) =>
  actions.updateIsUsingCurrentLocation({ value });

export const updateCurrentCoords = (lat, lng) =>
  actions.updateCurrentCoordinates({ lat, lng });

export const clearLocation = () => actions.clearLocation();

export const clearChoices = () => actions.clearChoices();

export const showMap = () => actions.mapShown();

export const hideMap = () => actions.mapHidden();

export const loadChoices = (postalCode) =>
  apiCallBegan({
    endpoint: endpoints.POSTAL_CODES,
    params: { postal_code: postalCode },
    onSuccess: actions.choicesReceived.type,
    onError: actions.clearChoices.type,
  });

export const updateArea = (area) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      endpoint: endpoints.UPDATE_AREA,
      data: {
        ...selectFormatedAddress(getState()),
        area: area,
      },
      onSuccess: actions.areaReceived.type,
    })
  );
};

export const addPolygonToDB = (polygons) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      endpoint: endpoints.ADD_POLYGONS,
      data: {
        ...selectFormatedAddress(getState()),
        polygons: polygons,
      },
    })
  );
};

export const loadAddress = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      endpoint: endpoints.ADDRESS,
      data: {
        ...selectFormatedAddress(getState()),
      },
      onSuccess: actions.addressReceived.type,
      onEmpty: actions.areaEmptied.type,
      onError: actions.areaEmptied.type,
    })
  );
};

export const validateAddress = (civicNumber, street, city, postalCode) =>
  apiCallBegan({
    endpoint: endpoints.VALIDATE_ADDRESS,
    data: {
      civicNumber,
      street,
      city,
      postalCode,
    },
    onSuccess: actions.validationReceived.type,
  });

export const changeAddressInput = (input) =>
  actions.addressInputSelected({ input });

export const emptyArea = () => actions.areaEmptied();

// Selectors
export const selectLocation = (state) => state.address.location;
export const selectPackageManualArea = (state) =>
  state.address.packageManualArea;
export const selectAllServiceManualArea = (state) =>
  state.address.servicesManualArea;
export const selectAllServicePolygons = (state) =>
  state.address.servicesPolygon;
export const selectServiceManualArea = (data) =>
  createSelector(
    (state) => state.address.servicesManualArea,
    (servicesManualArea) => {
      return servicesManualArea.hasOwnProperty(data["serviceId"])
        ? servicesManualArea[data["serviceId"]]
        : null;
    }
  );
export const selectServicePolygons = (data) =>
  createSelector(
    (state) => state.address.servicesPolygon,
    (servicesPolygon) => {
      return servicesPolygon.hasOwnProperty(data["serviceId"])
        ? servicesPolygon[data["serviceId"]]
        : null;
    }
  );
export const selectChoices = (state) => state.address.choices;
export const selectShowMap = (state) => state.address.ui.showMap;
export const selectArea = (state) => state.address.area;
export const selectIsUsingCurrentLocation = (state) =>
  state.address.location.usingCurrentLocation;

export const selectFormatedAddress = createSelector(
  (state) => state.address.location,
  (location) => formatLocationState(location)
);

const formatLocationState = (location) => {
  const { street, city, civicNumber, postalCode } = location;
  return {
    street,
    city,
    no_civic: civicNumber,
    postal_code: postalCode,
    province: "QC",
  };
};

export const addAddress = (address_data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      endpoint: endpoints.ADD_NEW_ADDRESS,
      data: {
        ...address_data,
      },
      onSuccess: actions.addAddressMsg.type,
      onNotFound: actions.addAddressError.type,
      onError: actions.addAddressError.type,
    })
  );
};

export const selectFormatedCity = createSelector(
  (state) => state.address.location.city,
  (city) => {
    const lowercase = city.toLowerCase();
    return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
  }
);

export const selectPolygons = createSelector(
  (state) => state.address.polygons,
  (polygons) => polygons
);

export const selectAvailableStreets = createSelector(
  (state) => state.address.location.city,
  (state) => state.address.choices.addresses,
  (city, addresses) => (city in addresses ? Object.keys(addresses[city]) : [])
);

export const selectAvailableCivics = createSelector(
  (state) => state.address.location.street,
  (state) => state.address.choices.addresses,
  (city, addresses) =>
    Object.entries(addresses)
      .map(([_, streetObj]) => streetObj)
      .filter((stretObj) => Object.keys(stretObj).includes(city))
      .map((streetObj) => streetObj[city])
      .reduce((acc, curr) => acc.concat(curr), [])
);

export const selectHasFilledAddress = createSelector(
  (state) => state.address.location,
  (location) => location.postalCode !== ""
);

export const selectPostalCode = createSelector(
  (state) => state.address.location.postalCode,
  (postalCode) => postalCode
);

export const selectAddressInput = createSelector(
  (state) => state.address.ui.input,
  (input) => input
);

export const selectUiMessage = createSelector(
  (state) => state.ui,
  ({ messageType, message }) => ({ messageType, message })
);

export const selectManualArea = createSelector(
  (state) => state.address,
  (address) => address.manual_area
);

export const selectIsDuplicateAddress = createSelector(
  (state) => state.address.hasContract,
  (valid) => valid
);

export const selectCityChoices = createSelector(
  (state) => state.address.choices.street,
  (state) => state.address.choices.civicNumber,
  (state) => state.address.choices.addresses,
  (state) => state.address.location,
  (allStreets, allCivicNumbers, choices, { street, civicNumber }) => {
    if (street !== "" && street !== null && allStreets.includes(street)) {
      let cities = Object.keys(choices).filter((city) =>
        Object.keys(choices[city]).includes(street)
      );
      if (
        civicNumber !== "" &&
        civicNumber !== null &&
        allCivicNumbers.includes(civicNumber)
      ) {
        cities = cities.filter((city) =>
          Object.keys(choices[city][street]["civic"]).includes(civicNumber)
        );
      }
      return cities;
    }

    if (
      civicNumber !== "" &&
      civicNumber !== null &&
      allCivicNumbers.includes(civicNumber)
    ) {
      let cities = [];
      Object.keys(choices).forEach((city) => {
        Object.keys(choices[city]).forEach((street) => {
          if (
            Object.keys(choices[city][street]["civic"]).includes(civicNumber) &&
            !cities.includes(city)
          )
            cities.push(city);
        });
      });
      return cities;
    }

    return Object.keys(choices);
  }
);

export const selectStreetChoices = createSelector(
  (state) => state.address.choices.civicNumber,
  (state) => state.address.choices.addresses,
  (state) => state.address.location,
  (allCivicNumbers, choices, { city, street, civicNumber }) => {
    if (
      civicNumber !== "" &&
      civicNumber !== null &&
      allCivicNumbers.includes(civicNumber)
    ) {
      if (city in choices) {
        return Object.keys(choices[city]).filter((key) =>
          Object.keys(choices[city][key]["civic"]).includes(civicNumber)
        );
      } else {
        let streets = [];
        Object.keys(choices).forEach((city) => {
          Object.keys(choices[city]).forEach((street) => {
            if (
              Object.keys(choices[city][street]["civic"]).includes(
                civicNumber
              ) &&
              !streets.includes(street)
            )
              streets.push(street);
          });
        });
        return streets;
      }
    }
    if (city === null || street === null || !(city in choices))
      return Object.values(choices)
        .map((key) => Object.keys(key))
        .reduce((acc, curr) => [...curr, ...acc], []);

    return Object.keys(choices[city]);
  }
);

export const selectCivicChoices = createSelector(
  (state) => state.address.choices.street,
  (state) => state.address.choices.addresses,
  (state) => state.address.location,
  (allStreets, choices, { city, street }) => {
    if (
      city !== null &&
      city in choices &&
      (street === null || !(street in choices[city]))
    ) {
      return Object.values(choices[city]).reduce(
        (acc, curr) => [...Object.keys(curr["civic"]), ...acc],
        []
      );
    }

    if (
      (city === null || !(city in choices)) &&
      street !== null &&
      street !== "" &&
      allStreets.includes(street)
    ) {
      let cvn = [];
      Object.values(choices).map((city) => {
        if (Object.keys(city).includes(street)) {
          cvn = [...cvn, ...Object.keys(city[street]["civic"])];
        }
      });
      return cvn;
    }

    if (
      city === null ||
      street === null ||
      !(city in choices) ||
      !(street in choices[city])
    )
      return Object.values(choices)
        .reduce((acc, curr) => [curr, ...acc], [])
        .map((city) => Object.values(city))
        .reduce((acc, curr) => [...curr, ...acc], [])
        .reduce((acc, curr) => [...Object.keys(curr["civic"]), ...acc], []);

    return Object.keys(choices[city][street]["civic"]);
  }
);

export const selectSecondStreetLineChoices = createSelector(
  (state) => state.address.choices.civicNumber,
  (state) => state.address.choices.street,
  (state) => state.address.choices.addresses,
  (state) => state.address.choices.address_line_2,
  (state) => state.address.location,
  (
    allCivicNumbers,
    allStreets,
    choices,
    allStreet2Options,
    { city, street, civicNumber }
  ) => {
    let street_2_options = [];
    if (
      civicNumber !== "" &&
      civicNumber !== null &&
      allCivicNumbers.includes(civicNumber)
    ) {
      Object.keys(choices).forEach((city) => {
        Object.keys(choices[city]).forEach((street) => {
          if (Object.keys(choices[city][street]["civic"]).includes(civicNumber))
            street_2_options = [
              ...choices[city][street]["civic"][civicNumber],
              ...street_2_options,
            ];
        });
      });
      return [...new Set(street_2_options)];
    } else if (
      city !== null &&
      city in choices &&
      (street === null || !(street in choices[city]))
    ) {
      Object.keys(choices[city]).forEach((street) => {
        Object.values(choices[city][street]["civic"]).forEach((street2List) => {
          street_2_options = [...street2List, ...street_2_options];
        });
      });
      return [...new Set(street_2_options)];
    } else if (
      (city === null || !(city in choices)) &&
      street !== null &&
      street !== "" &&
      allStreets.includes(street)
    ) {
      Object.keys(choices).forEach((city) => {
        if (Object.keys(choices[city]).includes(street)) {
          Object.values(choices[city][street]["civic"]).forEach(
            (street2List) => {
              street_2_options = [...street2List, ...street_2_options];
            }
          );
        }
      });
      return [...new Set(street_2_options)];
    } else if (
      city !== null &&
      city in choices &&
      street !== null &&
      street !== "" &&
      allStreets.includes(street)
    ) {
      Object.values(choices[city][street]["civic"]).forEach((street2List) => {
        street_2_options = [...street2List, ...street_2_options];
      });
      return [...new Set(street_2_options)];
    }
    return allStreet2Options;
  }
);

export const selectAddressCivicNumber = createSelector(
  (state) => state.address.location.civicNumber,
  (civicNumber) => civicNumber
);
